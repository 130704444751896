import * as React from "react";
import { useEffect } from "react";

import Box from "@mui/material/Box";
import { useGridApiRef } from "@mui/x-data-grid-premium";

import { getAllOfflineOrders, getOfflineOrdersFields } from "../../api/OfflineOrders";
import { markAsPaid } from "../../api/Other";

import { useRecoilValue } from "recoil";
import { permissionsState } from "../../atoms/atoms";

import DataGrid from "../../components/Datagrid/Datagrid";
import Error from "../../components/Error";
import MainContainer from "../../layouts/main";

import DetailPanelContent from "./components/DetailPanelContent";
import MarkAsPaid from "./components/MarkAsPaidModal";
import { errorMsg } from "../../common";
import Loading from "../../components/Loading";
import CancelOrderModal from "./components/CancelOrderModal";
import { performOrderAction } from "../../api/CustomerOrders";

export default function OfflineOrders() {
  const [allOrderDetails, setAllOrderDetails] = React.useState([]);
  const [searchFieldsLoading, setSearchFieldsLoading] = React.useState(true);
  const [ordersLoading, setOrdersLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [columns, setColumns] = React.useState(null);

  const permissions = useRecoilValue(permissionsState);

  const [searchFields, setSearchFields] = React.useState();
  const [columnPermissions, setColumnPermissions] = React.useState(null);

  const [openModal, setOpenModal] = React.useState(false);
  const [orderDetails, setOrderDetails] = React.useState(null);

  const [markAsPaidLoading, setMarkAsPaidLoading] = React.useState(false);

  const [openCancelModal, setOpenCancelModal] = React.useState(false);
  const [cancelOrderLoading, setCancelOrderLoading] = React.useState(false);

  const handleOpen = (order, action) => {
    if (action === "markAsPaid") {
      setOpenModal(true);
      setOrderDetails({
        id: order.orderId,
        fullName: `${order.firstName} ${order.lastName}`,
      });
    } else if (action === "cancelOrder") {
      setOpenCancelModal(true);
      setOrderDetails({
        id: order.orderId,
        fullName: `${order.firstName} ${order.lastName}`,
      });
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpenCancelModal(false);
  };

  useEffect(() => {
    let didComponentMount = false;

    getOfflineOrdersFields()
      .then((rsp) => {
        if (didComponentMount) return;
        let columnOrderValues = {};
        const newColumns = [];

        rsp.searchFields.forEach((field, i) => {
          const fieldName = field.fieldName;
          const columns = permissions?.["offlineOrders"]?.["orderDetails"]?.["columns"];

          if (permissions?.id === "Super Admin" || (columns && columns[fieldName])) {
            newColumns.push({ id: i, ...field });
          }
        });
        setColumnPermissions(columnOrderValues);

        setColumns(newColumns);
        setSearchFields(rsp.searchFields);
        setSearchFieldsLoading(false);

        getAllOfflineOrders()
          .then((rsp) => {
            const sortedOrderUnits = rsp.allOrderUnits.sort((a, b) => {
              return new Date(b.orderDate) - new Date(a.orderDate);
            });

            setAllOrderDetails(sortedOrderUnits);
            setOrdersLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setErrorMessage(errorMsg);
            setAllOrderDetails([]);
            setOrdersLoading(false);
          });
      })
      .catch((err) => {
        console.error(err);
        setSearchFieldsLoading(false);
      });

    return () => {
      didComponentMount = true;
    };
  }, []);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );

  const getDetailPanelHeight = React.useCallback(() => 400, []);

  const markAsPaidSubmit = () => {
    setMarkAsPaidLoading(true);

    markAsPaid(orderDetails.id)
      .then((rsp) => {
        setMarkAsPaidLoading(false);
        setAllOrderDetails(
          allOrderDetails.map((order) => ({
            ...order,
            inProgressMarkAsPaid: orderDetails.id === order.id ? true : order.inProgressMarkAsPaid,
          }))
        );
        handleClose();
      })
      .catch((err) => {
        setMarkAsPaidLoading(false);
        console.log(err);
      });
  };

  const handleCancelOrder = () => {
    setCancelOrderLoading(true);

    performOrderAction({
      action: "CANCEL_ORDER",
      details: [
        {
          wixOrderNumber: orderDetails.id,
          wixNumber: orderDetails.id,
        },
      ],
    })
      .then((rsp) => {
        console.log(rsp);
        setCancelOrderLoading(false);
        setAllOrderDetails(
          allOrderDetails.map((order) =>
            orderDetails.id === order.id ? { ...order, canceled: rsp[0].canceled } : order
          )
        );
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setCancelOrderLoading(false);
      });
    // cancelOrder(orderDetails.id)
    //   .then((rsp) => {
    //     setCancelOrderLoading(false);
    //     // Update the order status in the list
    //     setAllOrderDetails(
    //       allOrderDetails.map((order) => ({
    //         ...order,
    //         status: orderDetails.id === order.id ? 'CANCELLED' : order.status,
    //       }))
    //     );
    //     handleClose();
    //   })
    //   .catch((err) => {
    //     setCancelOrderLoading(false);
    //     console.error(err);
    //     // You might want to show an error message here
    //   });
  };

  return (
    <MainContainer title="Offline Orders">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          height: "calc(100vh - 50px)",
        }}
      >
        {searchFieldsLoading && <Loading sx={{ width: 40, mx: "auto", mt: 3 }} />}

        {errorMessage && (
          <Error
            sx={{ width: "100%", margin: "auto", mb: 2, borderRadius: 0 }}
            errorMessage={errorMessage}
          />
        )}

        {!!searchFields && !!columnPermissions && (
          <Box
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <DataGrid
              noBorder
              rowID="id"
              tableName="offlineOrders"
              searchFields={columns}
              rows={allOrderDetails}

              storage={{
                columnWidths: "offlineOrdersWidths",
                columns: "offlineOrdersColumns",
                visibilityModel: "offlineOrdersVisibility",
              }}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              loading={ordersLoading}
              hideFooter
              openModal={handleOpen}
            />
          </Box>
        )}
        {!!orderDetails && (
          <>
            <MarkAsPaid
              markAsPaidLoading={markAsPaidLoading}
              orderDetails={orderDetails}
              open={openModal}
              handleClose={handleClose}
              onSubmit={markAsPaidSubmit}
            />
            <CancelOrderModal
              cancelOrderLoading={cancelOrderLoading}
              orderDetails={orderDetails}
              open={openCancelModal}
              handleClose={handleClose}
              onSubmit={handleCancelOrder}
            />
          </>
        )}
      </Box>
    </MainContainer>
  );
}
