import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Menu, MenuItem } from "@mui/material";
import DataGrid from "../../../components/Datagrid/Datagrid";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/DeleteModal";
import { performInventoryAction } from "../../../api/InventoryOrders";
import HistoryModal from "../modals/historyModal";
import AllowOrdersWhenOutOfStock from "../modals/allowOrdersWhenOutOfStock";

export const InventoryTableView = ({
  page,
  sortedRows,
  columns,
  ordersLoading,
  searchFieldOptions,
  refetch,
  handleRowSelection,
  handleSelectionCheck,
  selectedRows,
  toggleView,
  view,
  selectedWarehouse,
  handleOrdersChange,
}) => {
  let navigate = useNavigate();
  const isStockOrders = page === "stockOrders" || page === "auditCorrections";

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [openAllowOrdersModal, setOpenAllowOrdersModal] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const groupingModel = [
    page === "stockOrders" || page === "auditCorrections" ? "warehouseordernumber" : "category",
  ];

  const handleOpenModal = () => {
    setOpenAllowOrdersModal(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setOpenAllowOrdersModal(false);
  };

  const onRowClick = (params, e) => {
    console.log(params);
    // if (
    //   params?.row?.warehouseordernumber ||
    //   (params.field !== "flag" && params.field !== "productimage" && params.formattedValue)
    // ) {
    //   navigate(`/inventoryOrder/${params?.row?.warehouseordernumber || params.formattedValue}`);
    // }
    
  };

  const handleContextMenu = ({ rowData, position }) => {
    if (!isStockOrders) {
      setContextMenu({ rowData, position });
    }
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  // Handler for opening delete modal
  const handleOpenDeleteModal = (id) => {
    setDeleteItemId(id);
    setDeleteModalOpen(true);
    handleCloseContextMenu();
  };

  // Handler for closing delete modal
  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setDeleteItemId(null);
  };

  // Handler for delete action
  const handleDeleteItem = async () => {
    setDeleteLoading(true);
    try {
      const payload = {
        action: "DELETE_INVENTORY_ORDER",
        details: [
          {
            warehouseOrderNumber: deleteItemId,
          },
        ],
      };
      const data = await performInventoryAction(payload);
      console.log(data);
      refetch(); // Refresh the data
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      setDeleteLoading(false);
      handleCloseDeleteModal();
    }
  };

  // Handler for opening history modal
  const handleOpenHistoryModal = () => {
    if (contextMenu) {
      setSelectedItem(contextMenu.rowData);
      setHistoryModalOpen(true);
      handleCloseContextMenu();
    } else if (selectedItem) {
      setHistoryModalOpen(true);
    }
    handleMenuClose();
  };

  // Handler for closing history modal
  const handleCloseHistoryModal = () => {
    setHistoryModalOpen(false);
    setSelectedItem(null);
  };

  const handleMenuClick = (event, item) => {
    setSelectedItem(item);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <AllowOrdersWhenOutOfStock
        open={openAllowOrdersModal}
        handleClose={handleCloseModal}
        selectedItem={selectedItem}
        handleOrdersChange={handleOrdersChange}
      />
      <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleOpenHistoryModal}>Show History</MenuItem>
        <MenuItem onClick={handleOpenModal}>
          {selectedItem?.alloworderswhenoos
            ? "Disallow Orders When Out of Stock"
            : "Allow Orders When Out of Stock"}
        </MenuItem>
      </Menu>
      <Box
        sx={{
          height: {
            xs: `calc(100% - ${
              page === "stockPredictions" && selectedWarehouse !== "all" ? 180 : 105
            }px)`,
            md: `calc(100% - ${
              page === "stockPredictions" && selectedWarehouse !== "all" ? 123 : 50
            }px)`,
            lg: `calc(100% - ${
              page === "stockPredictions" && selectedWarehouse !== "all" ? 123 : 50
            }px)`,
          },
          width: "100%",
          overflow: "hidden",
        }}
      >

        <DataGrid
          noBorder
          rowID={isStockOrders ? "rownumber" : "sid"}
          rows={sortedRows}
          onRowClick={onRowClick}
          rowHeight={75}
          storage={{
            pinned: `${page}pinnedLeft`,
            columns: `${page}Columns`,
            visibilityModel: `${page}Visibility`,
          }}
          tableName={page}
          searchFields={columns}
          loading={ordersLoading}
          handleRowSelection={isStockOrders && handleRowSelection}
          handleSearch={refetch}
          componentProps={{
            toolbar: {
              tableName: page,
              searchFields: searchFieldOptions,
              handleSearch: refetch,
              page,
              viewType: isStockOrders ? "card" : "tile",
              selectedRows,
              toggleView: toggleView,
              view: view,
            },
          }}
          groupingModel={groupingModel}
          groupingColDef={{
            headerName: isStockOrders ? "Warehouse Order Number" : "Category",
          }}
          handleSelectionCheck={handleSelectionCheck}
          handleOpenDeleteModal={page !== "currentStock" && handleOpenDeleteModal}
          onContextMenu={handleContextMenu}
          handleMenuClick={handleMenuClick}
        />
      </Box>
      {!isStockOrders && (
        <Menu
          open={Boolean(contextMenu)}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu ? { top: contextMenu.position.y, left: contextMenu.position.x } : undefined
          }
        >
          <MenuItem onClick={handleOpenHistoryModal}>Show History</MenuItem>
        </Menu>
      )}
      {deleteItemId && (
        <DeleteModal
          openDeleteModal={deleteModalOpen}
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleDeleteItem={handleDeleteItem}
          message={`Are you sure you want to delete order ${deleteItemId}?`}
          loading={deleteLoading}
        />
      )}
      {selectedItem && (
        <HistoryModal
          open={historyModalOpen}
          onClose={handleCloseHistoryModal}
          item={selectedItem}
          searchFields={searchFieldOptions}
        />
      )}
    </Box>
  );
};
