import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  cardViewSettingsState,
  selectedCardsState,
} from "../../../atoms/atoms";
import { Box, Paper, Typography, Checkbox } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useDateFormat } from "../../../contexts/DateFormatContext";
import { renderCellComponent } from "../../Datagrid/utils";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function Card({ table, item }) {
  const { dateFormat } = useDateFormat();
  const settings = useRecoilValue(cardViewSettingsState);
  const navigate = useNavigate();
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedCards, setSelectedCards] = useRecoilState(selectedCardsState);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    height: 'auto',
    background: item["orderpaymentstatus"] === "NOT_PAID" || item["canceled"] === true
      ? "#DCDCDC"
      : "white",
  };

  const handleContextMenu = (event) => {
    if (window.getSelection().toString()) {
      return;
    } else {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : null
      );
    }
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const openInNewTab = () => {
    let url;
    if (table?.toLowerCase() === "orderunit" && item?.wixordernumber) {
      url = `/order/${item.wixordernumber}`;
    } else if (
      table?.toLowerCase() === "inventoryorders" &&
      item?.warehouseordernumber
    ) {
      url = `/inventoryOrder/${item.warehouseordernumber}`;
    }

    if (url) {
      window.open(url, "_blank");
    }
    handleClose();
  };

  const onCardClick = (event, card) => {
    if (window.getSelection().toString() || contextMenu) {
      return;
    }

    if (event.target.id === "checkbox") {
      setSelectedCards((prevState) => {
        const selectedItemIDs = prevState.map((item) => item.id);
        return selectedItemIDs.includes(item.id)
          ? prevState.filter((prevItem) => prevItem.id !== item.id)
          : [...prevState, item];
      });
      return;
    }

    if (
      !event.target?.classList.contains("card-text") ||
      event.target?.tagName === "IMG"
    ) {
      let url;
      if (table.toLowerCase() === "orderunit" && card?.wixordernumber) {
        url = `/order/${card.wixordernumber}`;
      } else if (
        table.toLowerCase() === "inventoryorders" &&
        card?.warehouseordernumber
      ) {
        url = `/inventoryOrder/${card.warehouseordernumber}`;
      }

      if (url) {
        navigate(url);
      } else if (!table) {
        console.warn(
          "onCardClick: clicked whitespace, header or image, but no table: " +
            table
        );
      }
    }
  };
  const showCheckBox =    
    table?.toLowerCase() === "inventoryorders" ?  
      selectedCards?.length > 0
      ? selectedCards[0].warehouseordernumber === item.warehouseordernumber
      : true :
    table?.toLowerCase() === "orderunit"
      ? selectedCards?.length > 0
        ? selectedCards[0].warehouse === item.warehouse
        : true
      : false;

  return (
    <Paper
      ref={setNodeRef}
      style={style}
      {...attributes}
      onClick={(e) => onCardClick(e, item)}
      onContextMenu={handleContextMenu}
      sx={{
        padding: 2,
        boxShadow: 3,
        mt: 1,
        backgroundColor: "background.paper",
        borderRadius: 2,
        position: "relative",
        minHeight: "55px",
        height: 'auto',
        "& :hover": { 
          cursor: "pointer"
        },
       
      }}
    >
      {showCheckBox && (
        <Checkbox
          id="checkbox"
          checked={!!selectedCards.find((card) => card.id === item.id)}
          sx={
            settings.canMoveCards
              ? { position: "relative", top: -10, left: -10 }
              : {
                  position: "absolute",
                  top: 12,
                  right: 12,
                }
          }
        />
      )}
      {settings.canMoveCards && (
        <Box
          {...listeners}
          sx={{ position: "absolute", top: 12, right: 12, cursor: "grab" }}
        >
          <MenuIcon />
        </Box>
      )}
      <Box sx={{ paddingRight: "28px" }}>
        {settings?.columnsDisplayed?.map((searchField) => {
          return (
            <Box
              key={searchField.fieldName}
              sx={{ 
                mb: 1,
                "& :hover": {
                  cursor: "pointer"
                },
                
              }}
              className={"card-field-container"}
            >
              {settings.showColumnHeaders && (
                <Typography
                  fontSize={14}
                  sx={{
                    color: "#9E9E9E",
                    mb: "2px",
                  }}
                  className={"card-field-header"}
                >
                  {searchField.label || searchField.displayName}:
                </Typography>
              )}

              <Box
                onClick={onCardClick}
                sx={{
                  "& #card-text": {
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "normal",
                    lineHeight: 1.4,
                  },
                  "& p:hover": { 
                    cursor: "default"
                  },
                }}
                className={"card-field-value"}
              >
                {renderCellComponent(
                  searchField,
                  searchField.fieldName,
                  { value: item[searchField.fieldName], row: item },
                  () => {},
                  dateFormat,
                  false
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={openInNewTab}>Open in New Tab</MenuItem>
      </Menu>
    </Paper>
  );
}
