import React from 'react';
import { Box } from '@mui/material';
import Loading from '../../../components/Loading';
import TableView from './TableView';
import OrdersCardView from './CardView';

const ContentView = ({
  view,
  loading,
  orders,
  columns,
  searchFields,
  permissions,
  handlers,
  selection,
  hasFilter,
  totalRows,
  gridRef,
}) => {
  if (
    loading.searchFields &&
    (view === 'table' || (view === 'card' && !loading.orders))
  ) {
    return (
      <Box width="40px" mx="auto" mt={3}>
        <Loading />
      </Box>
    );
  }

  if (view === 'table' && !loading.searchFields && columns) {
    return (
      <TableView
        gridRef={gridRef}
        orders={orders}
        columns={columns}
        searchFields={searchFields}
        permissions={permissions}
        loading={loading.orders}
        handlers={handlers}
        selection={selection}
        hasFilter={hasFilter}
        totalRows={totalRows}
      />
    );
  }

  if (view === 'card' && !loading.searchFields) {
    return (
      <OrdersCardView
        orders={orders}
        columns={columns}
        loading={loading.orders}
      />
    );
  }

  return null;
};

export default React.memo(ContentView);
