export const PERMISSIONS = {
  general: [
    {
      fieldName: "addOthers",
      displayName: "Add Others",
    },
    {
      fieldName: "addPermissionTypes",
      displayName: "Add Role Types",
    },
    {
      fieldName: "changePermissions",
      displayName: "Change Permissions",
    },
    {
      fieldName: "editSharedFilters",
      displayName: "Edit All Shared Filters",
    },
  ],
  customerOrders: {
    columns: [],
    actions: [
      {
        fieldName: "edit",
        displayName: "Edit",
      },
      {
        fieldName: "bulkEdit",
        displayName: "Bulk Edit",
      },
      {
        fieldName: "search",
        displayName: "Search",
      },
      {
        fieldName: "viewHiddenOrders",
        displayName: "View Hidden Orders",
      },
      {
        fieldName: "resendOrderConfirmation",
        displayName: "Resend Order Confirmation",
      },
      {
        fieldName: "cancelOrder",
        displayName: "Cancel Order",
      },
      {
        fieldName: "holdOrder",
        displayName: "Hold Order",
      },
      {
        fieldName: "markAsDelivered",
        displayName: "Mark As Delivered",
      },
      {
        fieldName: "editOrderNotes",
        displayName: "Change Supplier Notes",
      },
      {
        fieldName: "scheduleItem",
        displayName: "Schedule Item",
      },
      {
        fieldName: "shipItem",
        displayName: "Ship Item",
      },
      {
        fieldName: "editSerialNumber",
        displayName: "Add/Edit Serial Number",
      },
      {
        fieldName: "createCustomerTicket",
        displayName: "Create Customer Ticket",
      },
      {
        fieldName: "updateStatus",
        displayName: "Force Shipping Status Update",
      },
      {
        fieldName: "changeWarehouse",
        displayName: "Change Warehouse",
      },
      {
        fieldName: "changeItemCondition",
        displayName: "Change Item Condition",
      },
      {
        fieldName: "swapProduct",
        displayName: "Swap Product",
      },
      {
        fieldName: "updateShippingAnalytics",
        displayName: "Update Shipping Analytics",
      },
      {
        fieldName: "editOrderDetails",
        displayName: "Update Order Information",
      },
      {
        fieldName: "recreateConsignment",
        displayName: "Recreate Consignment",
      },
      {
        fieldName: "addFreeProductToOrder",
        displayName: "Add Free Product To Order",
      },
    ],
  },
  offlineOrders: {
    orderDetails: {
      columns: [],
      actions: [
        {
          fieldName: "edit",
          displayName: "Edit",
        },
        {
          fieldName: "search",
          displayName: "Search",
        },
      ],
    },
    products: {
      columns: [],
      actions: [
        {
          fieldName: "add",
          displayName: "Add",
        },
        {
          fieldName: "delete",
          displayName: "Delete",
        },
      ],
    },
  },
  inventoryOrders: {
    currentStock: {
      columns: [],
      actions: [],
    },
    stockPredictions: {
      columns: [],
      actions: [],
    },
    stockOrders: {
      columns: [],
      actions: [
        {
          fieldName: "canBuildOrders",
          displayName: "Can Build Orders",
        },
      ],
    },
  },
  accounting: {
    orderReimbursements: {
      pending: {
        columns: [],
        actions: [],
      },
      readyForPayment: {
        columns: [],
        actions: [],
      },
      paid: {
        columns: [],
        actions: [],
      },
      completed: {
        columns: [],
        actions: [],
      },
    },
  },
  database: {
    products: {
      columns: [],
      actions: [],
    },
  },
};

// Permission mapping for data grid names to their actual permission paths
export const PERMISSION_PATH_MAP = {
  stockOrders: ["inventoryOrders", "stockOrders"],
  stockPredictions: ["inventoryOrders", "stockPredictions"],
  currentStock: ["inventoryOrders", "currentStock"],

  orderReimbursementsPending: ["accounting", "orderReimbursements", "pending"],
  orderReimbursementsReadyForPayment: [
    "accounting",
    "orderReimbursements",
    "readyForPayment",
  ],
  orderReimbursementsPaid: ["accounting", "orderReimbursements", "paid"],
  orderReimbursementsCompleted: [
    "accounting",
    "orderReimbursements",
    "completed",
  ],

  offlineOrderDetails: ["offlineOrders", "orderDetails"],
  offlineProducts: ["offlineOrders", "products"],

  customerOrders: ["customerOrders"],
};
