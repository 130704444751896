import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getImage } from '../Datagrid/utils/utils';
import Loading from '../Loading';

const TileCard = React.memo(
  ({
    item,
    highlightInfo,
    imageField,
    tileViewSettings,
    renderField,
    onMenuClick,
  }) => {
    const {
      isHighlighted,
      highlightText,
      highlightColor,
      highlightHeaderColour,
    } = highlightInfo;

    const cardContent = React.useMemo(
      () => (
        <CardContent sx={{ flexGrow: 1 }}>
          {!tileViewSettings?.columnsDisplayed && <Loading />}
          {tileViewSettings?.columnsDisplayed?.map((field, fieldIndex) => (
            <Box key={fieldIndex}>{renderField(item, field)}</Box>
          ))}
        </CardContent>
      ),
      [item, tileViewSettings?.columnsDisplayed, renderField]
    );

    return (
      <Card
        sx={{
          '&:hover': {
            boxShadow: 1,
          },
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
          backgroundColor:
            isHighlighted && !!tileViewSettings.columnsDisplayed
              ? highlightColor
              : 'inherit',
          pb: 0,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: isHighlighted && !!tileViewSettings.columnsDisplayed ? 32 : 0,
            right: 0,
            zIndex: 2,
          }}
        >
          <IconButton
            size="small"
            onClick={(e) => onMenuClick(e, item)}
            sx={{
              color: highlightColor ? 'inherit' : 'primary.main',
              backgroundColor: highlightColor
                ? 'rgba(255, 255, 255, 0.7)'
                : 'transparent',
              '&:hover': {
                backgroundColor: highlightColor
                  ? 'rgba(255, 255, 255, 0.9)'
                  : 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
        {isHighlighted && !!tileViewSettings.columnsDisplayed && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              backgroundColor: highlightHeaderColour,
              color: 'white',
              padding: '4px 8px',
              textAlign: 'center',
              zIndex: 1,
            }}
          >
            <Typography variant="body2">{highlightText}</Typography>
          </Box>
        )}
        {imageField && (
          <Box
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'center',
              mt: isHighlighted ? 4 : 0,
            }}
          >
            <CardMedia
              component="img"
              height="140"
              image={
                getImage(item[imageField.fieldName]) ||
                item[imageField.fieldName]
              }
              alt={item.inventoryItem || 'Product Image'}
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: '140px',
              }}
            />
          </Box>
        )}
        {cardContent}
      </Card>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.item === nextProps.item &&
      prevProps.highlightInfo === nextProps.highlightInfo &&
      prevProps.imageField === nextProps.imageField &&
      prevProps.tileViewSettings === nextProps.tileViewSettings &&
      prevProps.renderField === nextProps.renderField
    );
  }
);

export default TileCard;