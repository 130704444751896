import { useState, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { customerOrdersRowIndexState } from '../../../atoms/atoms';

export const useGridInitialization = (dataGridName, dataGridRef, apiRef) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [customerOrdersRowIndex, setCustomOrderRowIndex] = useRecoilState(
    customerOrdersRowIndexState
  );

  const handleGridReady = useCallback(() => {
    if (apiRef.current && Object.keys(apiRef.current).length > 0) {
      setIsInitialized(true);
      if (dataGridRef) {
        dataGridRef.current = apiRef.current;
      }

      if (
        dataGridName === "customerOrders" &&
        customerOrdersRowIndex !== null &&
        customerOrdersRowIndex !== 0
      ) {
        setTimeout(() => {
          if (apiRef.current?.scrollToIndexes) {
            apiRef.current.scrollToIndexes({
              rowIndex: customerOrdersRowIndex,
            });
            setCustomOrderRowIndex(0);
          }
        }, 100);
      }
    }
  }, [
    dataGridName,
    customerOrdersRowIndex,
    apiRef,
    dataGridRef,
    setCustomOrderRowIndex,
  ]);

  return {
    isInitialized,
    handleGridReady
  };
}; 