import axios from "./axios";
import { API_ORDER_LINK_URL, API_SEARCH_LINK_URL } from "../consts";
import { isCancel } from "axios";

function getInventoryOrders(filter) {
  return axios
    .get(
      API_SEARCH_LINK_URL +
        `/searchInventoryOrders${filter ? `?${filter}` : ""}`
    )
    .then((response) => response.data);
}

function getInStockOrders(filter, cancelToken) {
  return axios
    .get(
      API_SEARCH_LINK_URL +
        `/searchInventoryInStock${filter ? `?${filter}` : ""}`,
      {
        cancelToken: cancelToken,
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      if (isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        // Handle other errors
        throw error;
      }
    });
}

function getToOrderOrders(filter) {
  return axios
    .get(
      API_SEARCH_LINK_URL +
        `/searchInventoryToOrder${filter ? `?${filter}` : ""}`
    )
    .then((response) => response.data);
}

function performInventoryAction(payload) {
  return axios
    .post(API_ORDER_LINK_URL + "/performInventoryAction", payload)
    .then((response) => response.data);
}

function getInventoryOrderRecommendedQty(
  warehouse,
  containerSize,
  lockedPidMap
) {
  return axios
    .get(
      API_ORDER_LINK_URL +
        `/getInventoryOrderRecommendedQty?warehouse=${warehouse}&containerSize=${containerSize}&lockedPidMap=${JSON.stringify(
          lockedPidMap
        )}`
    )
    .then((response) => response.data);
}

function setWarehouseStockOverride(sid, allowOrdersWhenOOS, holdUntilDate) {
  return axios
    .post(API_ORDER_LINK_URL + "/setWarehouseStockOverride", {
      sid,
      allowOrdersWhenOOS,
      holdUntilDate,
    })
    .then((response) => response.data);
}

export {
  getInventoryOrders,
  getInStockOrders,
  getToOrderOrders,
  performInventoryAction,
  getInventoryOrderRecommendedQty,
  setWarehouseStockOverride,
};
