import React, { useEffect, useState } from "react";
import { GridToolbarContainer, useGridApiContext } from "@mui/x-data-grid-premium";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import SearchOrdersInput from "../../SearchOrdersInput";
import ViewToggleButtons from "../../../pages/CustomerOrders/components/ViewToggleButtons";
import FiltersMenu from "../../Filter";

const CustomToolbar = ({
  tableName,
  handleSearch,
  searchFields,
  onActionSelect,
  page,
  selectedRows,
  ActionMenu,
  onUnpinAllColumns,
  onPinAllColumns,
  toggleView,
  view,
  viewType = "card",
}) => {
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPinned, setIsPinned] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  useEffect(() => {
    const savedPinState = localStorage.getItem(`${tableName}_pinState`);
    setIsPinned(savedPinState ? JSON.parse(savedPinState) : true);
  }, [tableName]);

  const handleExport = (options) => {
    apiRef.current.exportDataAsCsv(options);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePinToggle = () => {
    if (isPinned) {
      onUnpinAllColumns();
    } else {
      onPinAllColumns();
    }
    setIsPinned(!isPinned);
    localStorage.setItem(`${tableName}_pinState`, JSON.stringify(!isPinned));
    handleMenuClose();
  };

  const handleViewChange = () => {
    toggleView(view === "table" ? (viewType === "card" ? "card" : "tile") : "table");
    handleMenuClose();
  };

  const menuItems = [
    <MenuItem key="export" onClick={() => handleExport({ fileName: tableName })}>
      <FileDownloadIcon sx={{ mr: 1, fontSize: 20 }} />
      Export
    </MenuItem>,
    <MenuItem key="pin" onClick={handlePinToggle}>
      {isPinned ? (
        <>
          <PushPinOutlinedIcon sx={{ mr: 1, fontSize: 20 }} />
          Unpin Columns
        </>
      ) : (
        <>
          <PushPinIcon sx={{ mr: 1, fontSize: 20 }} />
          Pin Columns
        </>
      )}
    </MenuItem>,
  ];

  if (toggleView && isMobile) {
    menuItems.push(
      <Divider key="divider" />,
      <MenuItem key="viewToggle" onClick={handleViewChange}>
        {view === "table"
          ? `Switch to ${viewType === "card" ? "Card" : "Tile"} View`
          : "Switch to Table View"}
      </MenuItem>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        background: theme.palette.background.paper,
        py: 0.5,
        px: 1,
        borderBottom: `1px solid ${theme.palette.divider}`,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile && isSearchExpanded ? "column" : "row",
          alignItems: "center",
          width: "100%",
          gap: { xs: 0, sm: 0 },
        }}
      >
        {/* First Row - Always visible */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            width: isMobile && isSearchExpanded ? "100%" : "auto",
            flexGrow: 1,
          }}
        >
          {!isMobile && toggleView && (
            <Box mr={1}>
              <ViewToggleButtons view={view} onToggleView={toggleView} viewType={viewType} />
            </Box>
          )}
          <Box
            sx={{
              flexGrow: 1,
              width: isMobile && isSearchExpanded ? "100%" : "auto",
            }}
          >
            <SearchOrdersInput
              table={tableName}
              handleSearch={handleSearch}
              onSearchOpen={setIsSearchExpanded}
            />
          </Box>
        </Box>

        {/* Second Row - Only in mobile when search is expanded */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
            width: "100%",
            mt: isMobile && isSearchExpanded ? 1 : 0,
          }}
        >
          <FiltersMenu
            table={tableName}
            searchFieldOptions={searchFields}
            refetchOrders={handleSearch}
            hasActions={!!onActionSelect}
          />
          <Tooltip title="More options">
            <IconButton
              size="small"
              onClick={handleMenuClick}
              sx={{
                bgcolor: Boolean(anchorEl) ? "action.selected" : "transparent",
                "&:hover": {
                  bgcolor: "action.hover",
                },
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 3,
          sx: { minWidth: 200 },
        }}
      >
        {menuItems}
      </Menu>

      {/* Action Menu - Fixed to bottom right */}
      {onActionSelect && (
        <Box
          sx={{
            position: "fixed",
            bottom: 35,
            right: 16,
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <ActionMenu
            onActionSelect={onActionSelect}
            currentPage={page}
            selectedRows={selectedRows}
            variant="contained"
            sx={{
              boxShadow: theme.shadows[4],
              "&:hover": {
                boxShadow: theme.shadows[8],
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomToolbar;
