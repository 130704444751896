import React, { useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import { Menu, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import CreateIcon from "@mui/icons-material/Create";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

import FilterModal from "./Modal";

import {
  createFilter,
  deleteFilter,
  getFilter,
  updateFilter,
} from "../../api/Filters";
import Loading from "../Loading";
import Box from "@mui/material/Box";
import DeleteModal from "../DeleteModal";
import { loadFilter, loadUser, removeFilter } from "../../common";
import useSavedFilters from "../../hooks/useSavedFilters";
import { handleFilterChange } from "../../utils/filterUtils";
import { useRecoilValue } from "recoil";
import { permissionsState } from "../../atoms/atoms";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 300,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    ".FilterContainer:hover": { cursor: "pointer" },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const FiltersMenu = ({
  table,
  searchFieldOptions,
  refetchOrders,
  hasActions,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [filterSubmitLoading, setFilterSubmitLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(true);

  const [filter, setFilter] = useState(null);

  const [isEdit, setIsEdit] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [menuError, setMenuError] = useState("");

  const [selectedFilter, setSelectedFilter] = useState("");

  const [clearLoading, setClearLoading] = useState(false);

  const userPermissions = useRecoilValue(permissionsState);

  useEffect(() => {
    const getSavedFilter = async () => {
      const savedFilter = loadFilter(table);

      if (savedFilter) {
        try {
          const filter = (await getFilter(savedFilter)).filter;
          setSelectedFilter(filter);
        } catch (e) {
          let serverErrMsg = e.response?.data?.message
            ? ` (${e.response.data.message})`
            : "";
          console.error(
            "Couldn't get filter for: " + savedFilter + serverErrMsg,
            e
          );
          if (
            e.response?.status === 500 &&
            serverErrMsg.includes("Filter doesn't exist")
          ) {
            // todo better detection?
            removeFilter(table);
          }
        }
      }
      setFilterLoading(false);
    };
    getSavedFilter();
  }, [table]);

  const handleSelectedFilter = (filter) => setSelectedFilter(filter);
  const handleFilterUpdate = async (filter) => {
    await handleFilterChange(
      filter,
      handleSelectedFilter,
      refetchOrders,
      table
    );
  };

  const {
    savedFiltersLoading,
    savedFiltersError,
    savedFilters,
    handleSavedFiltersChange,
  } = useSavedFilters(table);

  const handleFiltersOpen = (e) => {
    setFiltersExpanded(true);
    setAnchorEl(e.currentTarget);
  };

  const handleFiltersClose = () => {
    setFiltersExpanded(false);
    setAnchorEl(null);
  };

  const handleOpenDeleteModal = (e, filter) => {
    e.stopPropagation();

    setFilter(filter);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleOpenModal = () => {
    setFilter(null);
    setIsEdit(false);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setErrorMessage("");
  };

  const handleAddFilterSubmit = (data) => {
    setFilterSubmitLoading(true);
    createFilter({ ...data, dataGridName: table })
      .then(async (rsp) => {
        if (!rsp.filter) {
          setErrorMessage(rsp.message);
        } else {
          await handleFilterUpdate(rsp.filter);
          handleSavedFiltersChange((prev) => [...prev, rsp.filter]);
          handleCloseModal();
          handleFiltersClose();
        }
        setFilterSubmitLoading(false);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.msg);
        console.log(err);
        setFilterSubmitLoading(false);
      });
  };

  const handleUpdateFilterSubmit = (newFilter) => {
    setFilterSubmitLoading(true);

    updateFilter(
      newFilter.id,
      {
        name: newFilter.name,
        isShared: newFilter.isShared,
        isTemporary: newFilter.isTemporary,
        filters: newFilter.filters,
        andOr: newFilter.andOr,
      },
      table
    )
      .then(async (rsp) => {
        if (!rsp.updatedFilters) {
          setErrorMessage(rsp.message);
        } else {
          if (newFilter.id === selectedFilter?.id)
            await handleFilterUpdate(newFilter);
          handleSavedFiltersChange(rsp.updatedFilters);

          handleCloseModal();
          setFilterSubmitLoading(false);
        }
      })
      .catch((err) => {
        setErrorMessage(
          err.response?.data?.msg ||
            "Error: something went wrong please try again. "
        );
        setFilterSubmitLoading(false);
        console.log(err);
      });
  };

  const handleDeleteFilterSubmit = () => {
    setFilterSubmitLoading(true);
    deleteFilter(filter.id, filter.dataGridName)
      .then(async (rsp) => {
        handleSavedFiltersChange(rsp.updatedFilters);
        setFilterSubmitLoading(false);
        handleCloseDeleteModal();
        if (filter.id === selectedFilter?.id) await handleFilterUpdate();
      })
      .catch((err) => {
        setErrorMessage(err.response.data.msg);
        console.log(err);
        setFilterSubmitLoading(false);
      });
  };
  const handleOpenEditFilterModal = (e, filter) => {
    e.stopPropagation();
    setFilter(filter);
    setIsEdit(true);
    setOpenModal(true);
  };

  const handleOpenCopyFilterModal = (e, filter) => {
    e.stopPropagation();
    setFilter(filter);
    setIsEdit(false);
    setOpenModal(true);
  };

  const handleClear = async () => {
    const temporaryFilters = savedFilters?.filter((f) => f.isTemporary) || [];

    // Update state with all temporary filters removed
    const updatedFilters = savedFilters.filter((f) => !f.isTemporary);
    handleSavedFiltersChange(updatedFilters);

    await handleFilterUpdate();
    handleFiltersClose();

    // Delete temporary filters
    if (temporaryFilters.length > 0) {
      setClearLoading(true);
      try {
        // Delete all filters
        await Promise.all(
          temporaryFilters.map((filter) =>
            deleteFilter(filter.id, filter.dataGridName)
          )
        );
      } catch (err) {
        console.error("Error deleting temporary filters:", err);
        setErrorMessage(
          err.response?.data?.msg || "Error deleting temporary filters"
        );
      } finally {
        setClearLoading(false);
      }
    }
  };

  const user = JSON.parse(loadUser());
  const userID = user?.id;

  const canEditShareFilters = userPermissions.general?.editSharedFilters;

  return (
    <>
      <Button
        aria-haspopup="true"
        aria-expanded={filtersExpanded ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={handleFiltersOpen}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          minWidth: { xs: 100, sm: 150 },
          maxWidth: { xs: selectedFilter ? 180 : 150, sm: "none" },
          width: { xs: "100%", sm: "auto" },
          display: "flex",
          alignItems: "center",
          px: { xs: 1.5, sm: 2 },
          height: { xs: 33, sm: 40 },
          "& .MuiButton-endIcon": {
            ml: 1,
            flexShrink: 0,
          },
        }}
        disabled={!!menuError}
        className={"filters-button"}
      >
        <Tooltip title={selectedFilter ? selectedFilter.name : "Filters"} arrow>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              flex: "1 1 auto",
              minWidth: 0,
              textAlign: "left",
              lineHeight: 1.3,
            }}
          >
            {!filterLoading ? (
              selectedFilter?.name ||
              (menuError || savedFiltersError ? "Error" : "Filters")
            ) : (
              <Loading size={10} />
            )}
          </div>
        </Tooltip>
      </Button>

      <StyledMenu
        anchorEl={anchorEl}
        open={filtersExpanded}
        onClose={handleFiltersClose}
      >
        {savedFilters?.length > 0 && (
          <MenuItem
            sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
            onClick={handleClear}
          >
            {clearLoading ? (
              <Loading size={20} />
            ) : (
              <Typography>Clear Filter</Typography>
            )}
          </MenuItem>
        )}

        {savedFiltersLoading && (
          <Box m={"auto"} width={"20px"}>
            <Loading size={20} />
          </Box>
        )}

        <MenuItem
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
          onClick={handleOpenModal}
        >
          <Typography>New Filter... </Typography>
        </MenuItem>

        {!savedFiltersLoading &&
          savedFilters
            ?.sort((a, b) => {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            })
            .map((filter) => (
              <Box
                key={filter.id}
                onClick={() => {
                  handleFiltersClose();
                  handleFilterUpdate(filter);
                }}
                display={"flex"}
                className={"FilterContainer"}
                alignItems={"center"}
                p={"3px"}
                pl={2}
                justifyContent={"space-between"}
                sx={{
                  background:
                    filter.id === selectedFilter?.id ? "#ebedef" : "inherit",
                  "&:hover": { background: "#f3f3f3" },
                }}
              >
                <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                  <Typography
                    noWrap
                    sx={{
                      maxWidth: { xs: "180px", sm: "210px" },
                      fontWeight:
                        filter.id === selectedFilter?.id ? 700 : "inherit",
                    }}
                    title={filter.name}
                  >
                    {filter.name}
                  </Typography>
                  {filter.isShared && (
                    <Button
                      disableRipple
                      sx={{
                        minWidth: "16px",
                        maxHeight: "28px",
                        mr: "2px",
                        py: "2px",
                        opacity: "70%",
                        "&:hover": { background: "transparent" },
                      }}
                      title={`Shared${
                        filter.creatorName ? " by " + filter.creatorName : ""
                      }`}
                    >
                      <PeopleAltOutlinedIcon
                        fontSize={"small"}
                        sx={{
                          fontSize: "1.2rem",
                          color: "rgb(55,65,81)",
                          mr: "0 !important",
                        }}
                      />
                    </Button>
                  )}{" "}
                  {filter.isTemporary && (
                    <Button
                      disableRipple
                      sx={{
                        minWidth: "16px",
                        maxHeight: "28px",
                        mr: "2px",
                        py: "2px",
                        opacity: "70%",
                        "&:hover": { background: "transparent" },
                      }}
                      title={
                        "This filter will be deleted if you click 'Clear Filter'"
                      }
                    >
                      <HourglassEmptyIcon
                        fontSize={"small"}
                        sx={{
                          fontSize: "1.2rem",
                          color: "rgb(55,65,81)",
                          mr: "0 !important",
                        }}
                      />
                    </Button>
                  )}
                </Box>
                <Box width={"auto"}>
                  {(filter.userID === userID || canEditShareFilters) && (
                    <Button
                      sx={{
                        minWidth: "16px",
                        maxHeight: "28px",
                        mr: "2px",
                        py: "2px",
                        opacity: "50%",
                        "&:hover": { opacity: "100%" },
                      }}
                      onClick={(e) => handleOpenEditFilterModal(e, filter)}
                    >
                      <CreateIcon
                        fontSize={"small"}
                        sx={{
                          fontSize: "1.2rem",
                          color: "rgb(55, 65, 81)",
                          mr: "0 !important",
                        }}
                      />
                    </Button>
                  )}
                  <Button
                    sx={{
                      minWidth: "16px",
                      maxHeight: "28px",
                      mr: 0,
                      py: "2px",
                      opacity: "50%",
                      "&:hover": { opacity: "100%" },
                    }}
                    onClick={(e) => handleOpenCopyFilterModal(e, filter)}
                  >
                    <ContentCopyIcon
                      fontSize={"small"}
                      sx={{
                        fontSize: "1.2rem",
                        color: "rgb(55, 65, 81)",
                        mr: "0 !important",
                      }}
                    />
                  </Button>

                  {(filter.userID === userID || canEditShareFilters) && (
                    <Button
                      sx={{
                        minWidth: "16px",
                        maxHeight: "28px",
                        ml: "2px",
                        opacity: "50%",
                        "&:hover": { opacity: "100%" },
                      }}
                      onClick={(e) => handleOpenDeleteModal(e, filter)}
                    >
                      <DeleteIcon
                        fontSize={"small"}
                        sx={{
                          fontSize: "1.2rem",
                          color: "rgb(55, 65, 81)",
                          mr: "0 !important",
                        }}
                      />
                    </Button>
                  )}
                </Box>
              </Box>
            ))}
      </StyledMenu>
      <FilterModal
        table={
          table === "currentStock" || table === "stockPredictions"
            ? "inventorysummarycomb"
            : table === "stockOrders"
            ? "inventoryorderscomb"
            : "orderUnit"
        }
        isEdit={isEdit}
        selectedFilter={filter}
        handleAddFilterSubmit={handleAddFilterSubmit}
        handleUpdateFilterSubmit={handleUpdateFilterSubmit}
        filterLoading={filterSubmitLoading}
        searchFieldOptions={searchFieldOptions}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        errorMessage={errorMessage}
      />
      <DeleteModal
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleDeleteItem={handleDeleteFilterSubmit}
        message={`Are you sure you want to delete filter: ${filter?.name}`}
        loading={filterSubmitLoading}
      />
    </>
  );
};
export default FiltersMenu;
