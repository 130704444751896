import React from 'react';
import { Box } from '@mui/material';
import DataGrid from '../../../components/Datagrid/Datagrid';

const TableView = ({
  gridRef,
  orders,
  columns,
  searchFields,
  permissions,
  loading,
  handlers,
  selection,
  hasFilter,
  totalRows,
}) => {
  return (
    <Box sx={{ height: `calc(100% - 50px)`, width: '100%' }}>
      <DataGrid
        dataGridRef={gridRef}
        noBorder
        rowID="id"
        rows={orders}
        rowHeight={75}
        storage={{
          pinned: 'pinnedLeft',
          columns: 'customerOrdersColumns',
          visibilityModel: 'customerOrdersVisibility',
        }}
        tableName="customerOrders"
        searchFields={columns}
        loading={loading}
        onRowClick={handlers.onRowClick}
        handleRowSelection={
          permissions.customerOrders.actions.bulkEdit &&
          handlers.handleRowSelection
        }
        handleSearch={handlers.handleSearch}
        selectedWarehouse={selection.selectedWarehouse}
        handleSelectionCheck={(params) =>
          params.row.shippingstatus === 'DELIVERED' ||
          params.row['orderpaymentstatus'] === 'NOT_PAID' ||
          params.row.canceled ||
          (selection.selectedWarehouse &&
            selection.selectedWarehouse !== params.row.warehouse)
        }
        componentProps={{
          toolbar: {
            dataGridName: 'customerOrders',
            tableName: 'customerOrders',
            searchFields: searchFields,
            handleSearch: handlers.handleSearch,
            toggleView: handlers.toggleView,
            view: 'table',
          },
        }}
        onRowsScrollEnd={hasFilter ? null : handlers.handleGridScroll}
        rowCount={Math.max(totalRows || 0, orders.length)}
        scrollEndThreshold={200}
        sortingMode="server"
        filterMode="server"
      />
    </Box>
  );
};

export default React.memo(TableView);
