import React from "react";
import { Paper, Box, Typography, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";
import { orderSettingsState, permissionsState } from "../../../../atoms/atoms";
import { useDateFormat } from "../../../../contexts/DateFormatContext";
import { renderCellComponent } from "../../../../components/Datagrid/utils/utils";
import ProductImage from "../../../Order/sections/ProductsDetailsCard/ProductImage";
import ActionsMenu from "../../../InventoryOrders/components/actionsMenu";

const images = require.context("../../../../images/flags-large", true, /\.webp$/);

const Details = ({ product, longestLabelLength }) => {
  const { dateFormat } = useDateFormat();
  const orderSettings = useRecoilValue(orderSettingsState);
  return (
    <Grid container spacing={0.2}>
      {orderSettings?.columnsDisplayed?.map((field) => {
        if (field.level === "item" && (product[field.fieldName] || field.dataType === "boolean")) {
          return (
            <Grid
              container
              item
              xs={12}
              key={field.fieldName}
              sx={{ ml: 1, display: "flex", flexWrap: "wrap" }}
            >
              <Box
                sx={
                  field.dataType === "boolean"
                    ? {
                        minWidth: "max-content",
                        alignContent: "center",
                      }
                    : {}
                }
              >
                <Typography
                  fontWeight="bold"
                  sx={{
                    mr: { xs: 0.3, sm: 1 },
                    minWidth: { xs: "auto", sm: `${longestLabelLength}ch` },
                    wordBreak: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                  }}
                >
                  {field.label}:
                </Typography>
              </Box>
              <Box
                sx={{
                  minWidth: "50%",
                  wordBreak: "break-word",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                }}
              >
                {renderCellComponent(
                  field,
                  field.fieldName,
                  { value: product[field.fieldName], row: product },
                  null,
                  dateFormat
                )}
              </Box>
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );
};

const FlagImage = ({ flag }) => {
  const getImage = (imageName) => {
    try {
      return images(`./${imageName}`);
    } catch (e) {
      console.warn(`Could not find image: ${imageName}`);
      return null;
    }
  };

  return (
    <img style={{ height: "60px" }} src={getImage(flag)} alt={flag.slice(0, -4)} loading="lazy" />
  );
};

const InventoryProductCard = ({ product, handleActionSelect, longestLabelLength }) => {
  const permissions = useRecoilValue(permissionsState);

  const shouldDisplayProductActionsMenu = !permissions.inventoryOrders?.actions?.editInventoryOrder;

  return (
    <Paper
      sx={{
        p: 3,
        mb: 1,
        position: "relative",
      }}
    >
      {shouldDisplayProductActionsMenu && (
        <Box
          sx={{
            top: { md: 16 },
            right: { md: 16 },
            display: { xs: "none", sm: "none", md: "flex" },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight="bold" variant={"h5"} ml={1.5}>
            {product.inventoryitem}
          </Typography>
          <ActionsMenu
            isSubItem
            onActionSelect={handleActionSelect}
            selectedRows={[product]}
            currentPage="stockOrders"
            forceGroupActions={false}
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          mt: { xs: 0, sm: 0, md: 1 },
        }}
      >
        <Box
          mb={{ xs: 2, sm: 2, md: 0 }}
          width={{ xs: "100%", sm: "100%", md: 160 }}
          display={"flex"}
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent={{ xs: "center", sm: "space-between" }}
        >
          <ProductImage product={product} />
          <Box display={{ xs: "flex", sm: "flex", md: "none" }} mt={{ xs: 2, sm: 0 }}>
            <FlagImage flag={product.flag} />
          </Box>
        </Box>

        <Box sx={{ flex: 1, pr: { xs: 0, sm: 2 } }}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Details longestLabelLength={longestLabelLength} product={product} />

            <Box display={{ xs: "none", sm: "none", md: "block" }} alignContent="center" pt={"6px"}>
              <FlagImage flag={product?.flag} />
            </Box>
          </Box>
        </Box>
      </Box>
      {shouldDisplayProductActionsMenu && (
        <Box display={{ xs: "block", sm: "block", md: "none" }} mt={2}>
          <ActionsMenu
            isSubItem
            onActionSelect={handleActionSelect}
            selectedRows={[product]}
            currentPage="stockOrders"
            forceGroupActions={false}
          />
        </Box>
      )}
    </Paper>
  );
};

export default InventoryProductCard;
