import React, { useCallback, useEffect, useState } from "react";

import Permissions from "./Permissions";
import Modal from "../../../../../../components/Modal";

const CreatePermissions = ({
  roleID,
  isEdit,
  message,
  permissionLoading,
  handleUpdateSubmit,
  handleAddSubmit,
  handleCloseModal,
  openModal,
  setMessage,
  title,
  duplicateRole,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState("");
  const [checkedState, setCheckedState] = useState({});
  const [nameError, setNameError] = useState(false);

  const [isReady, setIsReady] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);

  useEffect(() => {
    const initializeState = () => {
      if (duplicateRole) {
        setName(`${duplicateRole.name} (Copy)`);
        setDescription(duplicateRole.description);
        setIcon(duplicateRole.icon);
        setCheckedState(flattenObject(duplicateRole));
        setIsDuplicating(true);
      } else {
        setName("");
        setDescription("");
        setIcon("");
        setCheckedState({});
        setIsDuplicating(false);
      }
      setNameError(false);
      setIsReady(true);
    };

    if (openModal) {
      initializeState();
    } else {
      setIsReady(false);
    }
  }, [openModal, duplicateRole]);

  const flattenObject = (obj, prefix = "") => {
    return Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? prefix + "." : "";
      if (
        typeof obj[k] === "object" &&
        obj[k] !== null &&
        !Array.isArray(obj[k])
      ) {
        Object.assign(acc, flattenObject(obj[k], pre + k));
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});
  };

  const convertToNestedStructure = (flatState) => {
    const nestedState = {};
    Object.entries(flatState).forEach(([key, value]) => {
      if (
        !key.includes(".") ||
        key.endsWith(".columns") ||
        key.endsWith(".actions") ||
        key.endsWith(".products") ||
        key.endsWith(".orderDetails") ||
        key.endsWith(".stockOrders") ||
        key.endsWith(".currentStock") ||
        key.endsWith(".stockPredictions")
      ) {
        return;
      }

      const parts = key.split(".");
      let current = nestedState;

      for (let i = 0; i < parts.length; i++) {
        if (i === parts.length - 1) {
          // We've reached the end of the path, set the value
          current[parts[i]] = value;
        } else {
          // We're still traversing the path
          if (current[parts[i]] === undefined) {
            current[parts[i]] = {};
          } else if (typeof current[parts[i]] !== "object") {
            // If the current value is not an object (e.g., it's a boolean),
            // convert it to an object with a value property
            current[parts[i]] = { _value: current[parts[i]] };
          }
          current = current[parts[i]];
        }
      }
    });
    return nestedState;
  };

  const handleSubmit = useCallback(() => {
    if (!name) {
      setNameError(true);
      return;
    }
    setNameError(false);
    let newRole = convertToNestedStructure(checkedState);

    newRole.name = name;
    newRole.description = description;
    newRole.icon = icon;

    if (isEdit) {
      handleUpdateSubmit(roleID, newRole);
    } else {
      handleAddSubmit(newRole);
    }
  }, [
    name,
    checkedState,
    description,
    icon,
    isEdit,
    roleID,
    handleUpdateSubmit,
    handleAddSubmit,
  ]);

  return (
    <Modal
      large
      title={title}
      openModal={openModal}
      handleCloseModal={handleCloseModal}
      handleSubmit={handleSubmit}
      loading={permissionLoading}
      errorMsg={message}
    >
      {isReady && (
        <Permissions
          icon={icon}
          setIcon={setIcon}
          name={name}
          setName={setName}
          description={description}
          setDescription={setDescription}
          checkedState={checkedState}
          setCheckedState={setCheckedState}
          open={openModal}
          roleID={roleID}
          isEdit={isEdit}
          isDuplicating={isDuplicating}
          setMessage={setMessage}
          nameError={nameError}
        />
      )}
    </Modal>
  );
};

export default CreatePermissions;
