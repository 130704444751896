import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Table from "../../../components/Table";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import { Typography } from "@mui/material";

const PackageDropdown = ({ value, onChange, maxPackages }) => {
  const options = Array.from({ length: maxPackages }, (_, i) => i + 1);

  return (
    <Select
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      sx={{ minWidth: { xs: 100, sm: 300 } }}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

const RecreateConsignment = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);
  const [localSelection, setLocalSelection] = useState([]);

  const [changeState, setChangeState] = useState(0);

  // useEffect(() => {
  //   // Select all items by default
  //   const initialSelection = data.orders?.map((unit) => unit.id) || [];
  //   const wixOrderNumber = data.orders[0]?.wixordernumber;

  //   setState({
  //     wixOrderNumber: wixOrderNumber,
  //     deleteOldConsignment: false,
  //     selectionModel: initialSelection,
  //     packageItems: initialSelection.map((id, index) => ({
  //       id,
  //       package: index + 1,
  //     })),
  //   });
  //   setLocalSelection(initialSelection);
  // }, [data, setState]);

  // useEffect(() => {
  //   setLocalSelection(state.selectionModel || []);
  // }, [state.selectionModel]);

  // const handleSelectionChange = useCallback(
  //   (isSelected, field, id) => {
  //     if (field !== "selection") return;

  //     const updateSelectionModel = isSelected
  //       ? [...state.selectionModel, id]
  //       : state.selectionModel.filter((itemId) => itemId !== id);

  //     setLocalSelection(updateSelectionModel);
  //     setState((prevState) => ({
  //       ...prevState,
  //       selectionModel: updateSelectionModel,
  //       packageItems: updateSelectionModel.map((id, index) => ({
  //         id,
  //         package: prevState.packageItems.find((item) => item.id === id)?.package || index + 1,
  //       })),
  //     }));
  //   },
  //   [state.selectionModel, setState]
  // );

  // const handleInputChange = (value, field, id) => {
  //   if (field === "selection") {
  //     handleSelectionChange(value, field, id);
  //   } else if (field === "package") {
  //     setChangeState((prevState) => prevState + 1);
  //     setState((prevState) => ({
  //       ...prevState,
  //       packageItems: prevState.packageItems.map((item) =>
  //         item.id === id ? { ...item, [field]: value } : item
  //       ),
  //     }));
  //   }
  // };

  // const mainColumns = [
  //   {
  //     headerName: "",
  //     field: "selection",
  //     width: 70,
  //     editable: true,
  //     renderEditCell: (params) => {
  //       const isChecked = localSelection.includes(params.row.id);
  //       return (
  //         <Checkbox
  //           checked={isChecked}
  //           onChange={(event) =>
  //             handleSelectionChange(event.target.checked, "selection", params.row.id)
  //           }
  //         />
  //       );
  //     },
  //   },
  //   { field: "warehouse", headerName: "Warehouse", width: 100 },
  //   { field: "uid", headerName: "UID", width: 100 },
  //   { field: "descriptionshort", headerName: "Description", width: 100 },
  //   {
  //     field: "qty",
  //     headerName: "Qty",
  //     width: 100,
  //   },
  // ];

  // const packagingColumns = [
  //   { field: "uid", headerName: "UID" },
  //   {
  //     field: "package",
  //     headerName: "Package #",
  //     editable: true,
  //     renderEditCell: (params) => (
  //       <PackageDropdown
  //         value={state.packageItems.find((item) => item.id === params.row.id)?.package}
  //         onChange={(value) => handleInputChange(value, "package", params.row.id)}
  //         maxPackages={localSelection.length}
  //       />
  //     ),
  //   },
  // ];

  // const selectedRows = data.orders?.filter((order) => localSelection.includes(order.id));

  return (
    <Box>
      <Typography>
        This will replace the existing auto-created unmanifested freight consignment.
      </Typography>
      <FormControlLabel
        control={<Checkbox defaultChecked disabled={true} />}
        label="Replace/overwrite existing consignment"
      />
      {/* <Typography>
        Select line items to include in the new carrier consignment.{" "}
      </Typography>

      <Box width={"100%"} mt={2}>
        <Table
          key={localSelection.join(",")}
          columns={mainColumns}
          rows={data.orders || []}
          onCellChange={handleInputChange}
        />
      </Box>
      {selectedRows.length !== 0 && (
        <Box mt={2} width={"100%"}>
          <Table
            key={`packaging-${changeState}-${localSelection.length}`}
            columns={packagingColumns}
            rows={selectedRows || []}
            onCellChange={handleInputChange}
          />
        </Box>
      )}
      <Box mt={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.deleteOldConsignment}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  deleteOldConsignment: e.target.checked,
                }))
              }
            />
          }
          label="Delete old consignment for order"
        />
      </Box> */}
    </Box>
  );
};

export default RecreateConsignment;
