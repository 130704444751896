import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";

import Table from "../../../components/Table";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import BarcodeScanner from "../../Order/modals/BarcodeScannerModal";

const AddSerialNumbers = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [scanTarget, setScanTarget] = useState(null);

  useEffect(() => {
    setState({
      rows: data.orders.map((order) => ({
        ...order,
      })),
    });
  }, [data.orders, setState]);

  const handleCellChange = (value, field, id) => {
    setState((prevState) => {
      const updatedRows = prevState.rows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
      return { ...prevState, rows: updatedRows };
    });
  };

  const handleScanClick = (field, id) => {
    setScanTarget({ field, id });
    setIsScannerOpen(true);
  };

  const handleScanComplete = (barcode) => {
    if (scanTarget) {
      handleCellChange(barcode, scanTarget.field, scanTarget.id);
    }
    setIsScannerOpen(false);
  };

  const columns = [
    {
      field: "wixordernumber",
      headerName: "Order Number",
    },
    {
      field: "buyerfullname",
      headerName: "Buyer Full Name",
    },
    {
      headerName: "Model",
      field: "descriptionshort",
      sortable: false,
    },
    { headerName: "Qty", field: "qty", width: 100, sortable: false },

    {
      headerName: "Serial Number",
      field: "serialnumber",
      flex: 1,
      editable: true,
      renderEditCell: (params) => (
        <Box display="flex" alignItems="center" gap={1}>
          <TextField
            fullWidth
            value={params.row.serialnumber || ""}
            onChange={(e) =>
              handleCellChange(e.target.value, "serialnumber", params.row.id)
            }
          />
          <IconButton
            onClick={() => handleScanClick("serialnumber", params.row.id)}
            color="primary"
          >
            <QrCodeScannerIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography variant="body1" color="text.secondary">
        Add serial numbers to the following order units. Please ensure that the
        serial number is correctly filled in before proceeding.
      </Typography>

      <Box
        sx={{
          width: "100%",
          overflowX: "auto",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #f0f0f0",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
            borderBottom: "none",
          },
        }}
      >
        <Table
          columns={columns}
          rows={state.rows}
          onCellChange={handleCellChange}
        />
      </Box>
      <BarcodeScanner
        open={isScannerOpen}
        handleClose={() => setIsScannerOpen(false)}
        onScan={handleScanComplete}
      />
    </Box>
  );
};

export default AddSerialNumbers;
