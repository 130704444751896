import { useCallback } from 'react';

export const useInfiniteScroll = (hasFilter, onRowsScrollEnd, totalRows, currentRows) => {
  const handleGridScroll = useCallback(async () => {
    if (hasFilter) return null;
    
    if (onRowsScrollEnd && totalRows > currentRows.length) {
      await onRowsScrollEnd();
    }
  }, [hasFilter, onRowsScrollEnd, totalRows, currentRows.length]);

  return {
    handleGridScroll,
    infiniteScrollProps: hasFilter ? {} : {
      onRowsScrollEnd: handleGridScroll,
      rowCount: Math.max(totalRows || 0, currentRows.length),
      scrollEndThreshold: 200,
      columnBufferPx: 100,
      rowBuffer: 20,
      rowThreshold: 100
    }
  };
}; 