import { Alert, Snackbar } from "@mui/material";
import React from "react";

const ErrorSnackbar = ({ open, message, onClose }) => {
  return (
    <>
      {message && (
        <Snackbar
          open={open}
          onClose={onClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={onClose} severity="error" variant="filled">
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default ErrorSnackbar;
