import React, { useState } from "react";
import Box from "@mui/material/Box";
import { IconButton, useMediaQuery, useTheme, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SearchOrdersInput from "../../../components/SearchOrdersInput";
import CardViewSettingsModal from "../../../components/CardViewSettingsModal/Modal";
import CardView from "../../../components/CardView";
import FilterMenu from "../../../components/Filter";
import ViewToggleButtons from "../../CustomerOrders/components/ViewToggleButtons";
import Loading from "../../../components/Loading";

export const InventoryCardView = ({
  page,
  filteredWarehouseOrders,
  columns,
  searchFieldOptions,
  refetch,
  view,
  toggleView,
  ordersLoading,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewChange = () => {
    toggleView("table");
    handleMenuClose();
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          background: theme.palette.background.paper,
          py: 0.5,
          px: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile && isSearchExpanded ? "column" : "row",
            alignItems: "center",
            width: "100%",
            gap: { xs: 0, sm: 0 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: isMobile && isSearchExpanded ? "100%" : "auto",
              flexGrow: 1,
            }}
          >
            {!isMobile && (
              <Box mr={1}>
                <ViewToggleButtons view={view} onToggleView={toggleView} viewType="card" />
              </Box>
            )}
            <Box
              sx={{
                flexGrow: 1,
                width: isMobile && isSearchExpanded ? "100%" : "auto",
              }}
            >
              <SearchOrdersInput
                table={page}
                handleSearch={refetch}
                onSearchOpen={setIsSearchExpanded}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 1,
              width: "100%",
              mt: isMobile && isSearchExpanded ? 1 : 0,
            }}
          >
            <FilterMenu
              table={page}
              searchFieldOptions={searchFieldOptions}
              refetchOrders={refetch}
            />
            <CardViewSettingsModal dataGridName={page} searchFields={searchFieldOptions} />
            {isMobile && (
              <Tooltip title="More options">
                <IconButton
                  size="small"
                  onClick={handleMenuClick}
                  sx={{
                    bgcolor: Boolean(anchorEl) ? "action.selected" : "transparent",
                    "&:hover": {
                      bgcolor: "action.hover",
                    },
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 3,
          sx: { minWidth: 200 },
        }}
      >
        <MenuItem onClick={handleViewChange}>Switch to Table View</MenuItem>
      </Menu>

      <Box
        sx={{
          height: { xs: "calc(100% - 155px)", md: "calc(100% - 100px)" },
          width: "100%",
        }}
      >
        {searchFieldOptions && !ordersLoading ? (
          <CardView
            table="inventoryorders"
            data={filteredWarehouseOrders}
            searchFields={columns}
            dataGridName="stockOrders"
            defaultColumnHeader={{
              fieldName: "status",
              dataType: "string",
              label: "Status",
            }}
          />
        ) : (
          <Box width={"40px"} mx={"auto"} mt={4}>
            <Loading />
          </Box>
        )}
      </Box>
    </>
  );
};
