import React from 'react';
import { GridToolbarContainer } from "@mui/x-data-grid-premium";
import CustomToolbar from "./CustomToolBar";

export const DataGridHeader = React.memo(({ 
  componentProps,
  handleUnpinAllColumns,
  handlePinAllColumns,
  isPinned,
  ...otherProps 
}) => {
  return (
    <GridToolbarContainer sx={{p:0}}>
      <CustomToolbar
        {...componentProps}
        onUnpinAllColumns={handleUnpinAllColumns}
        onPinAllColumns={handlePinAllColumns}
        isPinned={isPinned}
        {...otherProps}
      />
    </GridToolbarContainer>
  );
}); 