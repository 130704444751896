import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  customerOrdersRowIndexState,
  customerOrdersState,
  permissionsState,
  selectedCardsState,
} from '../../../atoms/atoms';
import useViewToggle from '../../../hooks/useViewToggle';
import useCustomerOrders from '../../../hooks/useCustomerOrders';
import useSearchFields from '../../../hooks/useSearchFields';
import useModalManager from '../../../hooks/useModalManager';
import { loadFilter, loadSearchTerm } from '../../../common';

const useOrderSelection = (customerOrders) => {
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  const handleRowSelection = useCallback(
    (isNotEmpty, items) => {
      setSelectedRows(isNotEmpty ? items : null);
      setSelectedWarehouse(
        isNotEmpty && items.length > 0
          ? customerOrders.find((order) => order.id === items[0].id)?.warehouse
          : null
      );
    },
    [customerOrders, selectedRows?.length]
  );

  return {
    selectedRows,
    selectedWarehouse,
    handleRowSelection,
  };
};

export const useCustomerOrdersView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const loadingRef = useRef(false);

  const permissions = useRecoilValue(permissionsState);
  const [customerOrders, setCustomerOrders] =
    useRecoilState(customerOrdersState);
  const [selectedCards, setSelectedCards] = useRecoilState(selectedCardsState);
  const setCustomerOrdersRowIndex = useSetRecoilState(
    customerOrdersRowIndexState
  );

  const [view, toggleView] = useViewToggle('customerOrders');
  const { openModal, closeModal, currentModal } = useModalManager();
  const { selectedRows, selectedWarehouse, handleRowSelection } =
    useOrderSelection(customerOrders);

  const {
    loadOrders,
    ordersLoading,
    ordersError,
    setFilterId,
    setSearchTerm,
    page,
    totalRows,
    hasFilter,
  } = useCustomerOrders();

  const {
    searchFieldOptions,
    columns,
    searchFieldsLoading,
    searchFieldsError,
  } = useSearchFields(
    permissions?.['customerOrders'].columns,
    permissions?.id,
    'orderUnit'
  );

  const handleGridScroll = useCallback(async () => {
    if (loadingRef.current || ordersLoading) return;
    loadingRef.current = true;
    try {
      await loadOrders(page + 1);
    } finally {
      loadingRef.current = false;
    }
  }, [ordersLoading, page, loadOrders]);

  const handleSearch = useCallback(async () => {
    const newFilterId = loadFilter('customerOrders');
    const newSearchTerm = loadSearchTerm('customerOrders');

    setFilterId(newFilterId);
    setSearchTerm(newSearchTerm);
    await loadOrders(0);
  }, [setFilterId, setSearchTerm, loadOrders]);

  const onRowClick = useCallback(
    (params, e, openInNewTab) => {
      if (params?.row?.wixordernumber) {
        const rowIndex = params.api
          .getAllRowIds()
          .findIndex((id) => id === params.id);
        setCustomerOrdersRowIndex(rowIndex);

        const url = `/order/${params.row.wixordernumber}`;
        if (openInNewTab) {
          window.open(url, '_blank');
        } else {
          navigate(url);
        }
        if (e?.button === 0) e.preventDefault();
      }
    },
    [navigate, setCustomerOrdersRowIndex]
  );

  const onActionSelect = useCallback(
    (action) => {
      const rows = view === 'card' ? selectedCards : selectedRows;
      if (!rows?.length) return;

      const selectedOrders = customerOrders.filter((order) =>
        rows.some((row) => row.id === order.id)
      );
      openModal(action, { orders: selectedOrders });
    },
    [view, selectedCards, selectedRows, customerOrders, openModal]
  );

  const updateOrders = useCallback(
    (modalState, rsp = null) => {
      setCustomerOrders((prevState) =>
        prevState.map((order) => {
          const rows = Array.isArray(rsp) ? rsp : modalState.rows;
          const updatedRow = rows.find((row) => row.id === order.id);
          return updatedRow ? { ...order, ...updatedRow } : order;
        })
      );
    },
    [setCustomerOrders]
  );

  // Modal management
  const isScanning = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('modal') === 'shippingLabelScan';
  }, [location.search]);

  const handleCloseShippingLabelScanModal = useCallback(() => {
    navigate(location.pathname, { replace: true });
  }, [navigate, location.pathname]);

  // Initial load
  useEffect(() => {
    if (!customerOrders?.length) {
      loadOrders(0);
    }
  }, []);

  return {
    view,
    permissions,
    orders: {
      data: customerOrders,
      totalRows,
      hasFilter,
    },
    columns,
    searchFields: searchFieldOptions,
    loading: {
      orders: ordersLoading,
      searchFields: searchFieldsLoading,
    },
    errors: {
      hasError: ordersError || searchFieldsError,
    },
    modals: {
      currentModal,
      isScanning,
      isShippingLabelScanModalOpen: isScanning,
      closeModal,
      openModal,
    },
    selection: {
      selectedCards,
      setSelectedCards,
      selectedRows,
      selectedWarehouse,
    },
    handlers: {
      handleSearch,
      handleGridScroll,
      handleRowSelection,
      onRowClick,
      onActionSelect,
      updateOrders,
      toggleView,
      handleCloseShippingLabelScanModal,
    },
  };
};
