import { createTheme } from "@mui/material/styles";

const maincolorprimary = "#eb5e28";
const maincolorFocused = "rgba(235,94,40,0.85)";
const secondarycolor = "#ffffff";

const fontFamily = ["Montserrat", "Arial", "sans-serif"].join(",");

const lightColorTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: maincolorprimary,
      focus: maincolorFocused,
    },
    secondary: {
      main: secondarycolor,
    },
  },
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
  palette: {
    mode: "light",
    primary: {
      main: maincolorprimary,
      focus: maincolorFocused,
    },
  },
  typography: {
    fontFamily,
  },

  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "black",
          borderRadius: 5,
          margin: "0px",
        },
        arrow: {
          color: "black",
        },
      },
      defaultProps: {
        arrow: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          height: 40,
          lineHeight: 1.1,
          textAlign: "center",
          whiteSpace: "normal",
          wordWrap: "break-word",
          "@media (max-width:600px)": {
            height: "auto",
            fontSize: 14,
            lineHeight: 1.5,
          },
        },
        containedPrimary: {
          backgroundColor: maincolorprimary,
          color: "white",
          "&:hover": {
            backgroundColor: maincolorFocused,
          },
        },
        outlinedPrimary: {
          color: maincolorprimary,
          borderColor: maincolorprimary,
          backgroundColor: "white",
          "&:hover": {
            borderColor: maincolorFocused,
            backgroundColor: "rgba(235,94,40,0.04)", // Slight tint on hover
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          border: 0,
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          background: "white",
          border: 0,
          minHeight: 55,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          border: "1px solid #ccc",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "20px",
            background: "white",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: "20px !important",
          position: "static !important",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
        },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          border: "none",
          borderRadius: 20,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          marginTop: "0px",
          borderRadius: 20,
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            borderColor: lightColorTheme.palette.action.disabled,
          },
        },
      ],
    },
  },
});

export default theme;
