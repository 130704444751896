import {
  GRID_ROOT_GROUP_ID,
  gridClasses,
  GridFooterContainer,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useRecoilValue } from "recoil";
import { batchTwoLoadingState } from "../../../atoms/atoms";
import CircularProgress from "@mui/material/CircularProgress";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import IconButton from "@mui/material/IconButton";

export const CustomFooter = () => {
  const apiRef = useGridApiContext();
  const rowCount = apiRef.current.getRowsCount();
  const isBatchTwoLoading = useRecoilValue(batchTwoLoadingState);
  const groups = apiRef.current?.getRowNode(GRID_ROOT_GROUP_ID).children;

  const getExpandedGroups = () => {
    return groups.filter((groupId) => {
      const row = apiRef.current?.getRowNode(groupId);
      return row?.childrenExpanded;
    });
  };

  const handleCollapse = () => {
    const expandedGroups = getExpandedGroups();
    for (let id of expandedGroups) {
      apiRef.current.setRowChildrenExpansion(id, false);
    }
  };

  const handleExpand = () => {
    // Get the first 50 groups (or all if less than 50)
    const groupsToExpand = groups.slice(0, 50);
    for (let id of groupsToExpand) {
      apiRef.current.setRowChildrenExpansion(id, true);
    }
  };

  const hasGroups =
    Object.entries(
      apiRef.current?.getRowNode(GRID_ROOT_GROUP_ID).childrenFromPath
    ).length !== 0;

  const areAllGroupsCollapsed = hasGroups && getExpandedGroups().length === 0;

  return (
    <GridFooterContainer
      sx={{
        minHeight: "20px",
        alignItems: "center",
        "& .MuiTablePagination-root": {
          height: "100%",
        },
      }}
      className={gridClasses.footerContainer}
    >
      {hasGroups && !areAllGroupsCollapsed && (
        <IconButton size={"small"} onClick={handleCollapse}>
          <UnfoldLessIcon fontSize={"12px"} />
        </IconButton>
      )}
      {/*{hasGroups &&*/}
      {/*  (areAllGroupsCollapsed ? (*/}
      {/*    <IconButton size={"small"} onClick={handleExpand}>*/}
      {/*      <UnfoldMoreIcon fontSize={"12px"} />*/}
      {/*    </IconButton>*/}
      {/*  ) : (*/}
      {/*    <IconButton size={"small"} onClick={handleCollapse}>*/}
      {/*      <UnfoldLessIcon fontSize={"12px"} />*/}
      {/*    </IconButton>*/}
      {/*  ))}*/}
      <Box sx={{ display: { xs: "none", sm: "flex" } }} flex={1}></Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {isBatchTwoLoading && (
          <CircularProgress
            size={12}
            thickness={4}
            sx={{ color: "text.secondary" }}
          />
        )}
        <Typography
          sx={{
            marginRight: 1,
            fontSize: 12,
          }}
        >
          Total Rows: {rowCount}
        </Typography>
      </Box>
    </GridFooterContainer>
  );
};
