import React, { useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useSetRecoilState } from 'recoil';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Error from '../../components/Error';
import FilterMenu from '../../components/Filter';
import MainContainer from '../../layouts/main';
import CardViewSettingsModal from '../../components/CardViewSettingsModal/Modal';
import SearchOrdersInput from '../../components/SearchOrdersInput';
import ViewToggleButtons from './components/ViewToggleButtons';
import { ActionMenu } from './components/Menu';
import DynamicModal from './components/DynamicModal';
import ShippingLabelScanModal from './components/ShippingLabelScanModal';

import { selectedCardsState } from '../../atoms/atoms';
import { errorMsg } from '../../common';
import { useCustomerOrdersView } from './hooks/useCustomerOrdersView';
import ContentView from './views/ContentView';

const CardViewControls = ({
  view,
  toggleView,
  handleSearch,
  searchFieldOptions,
  isMobile,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const setSelectedCards = useSetRecoilState(selectedCardsState);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.paper,
        py: 0.5,
        px: 1,
        borderBottom: `1px solid ${theme.palette.divider}`,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile && isSearchExpanded ? 'column' : 'row',
          alignItems: 'center',
          width: '100%',
          gap: { xs: 0, sm: 0 },
        }}
      >
        {/* First Row - Always visible */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            width: isMobile && isSearchExpanded ? '100%' : 'auto',
            flexGrow: 1,
          }}
        >
          {!isMobile && (
            <Box mr={1}>
              <ViewToggleButtons
                view={view}
                onToggleView={(newView) => {
                  setSelectedCards([]);
                  toggleView(newView);
                }}
                viewType="card"
              />
            </Box>
          )}
          <Box
            sx={{
              flexGrow: 1,
              width: isMobile && isSearchExpanded ? '100%' : 'auto',
            }}
          >
            <SearchOrdersInput
              table="customerOrders"
              handleSearch={handleSearch}
              onSearchOpen={setIsSearchExpanded}
            />
          </Box>
        </Box>

        {/* Second Row - Only in mobile when search is expanded */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 1,
            width: '100%',
            mt: isMobile && isSearchExpanded ? 1 : 0,
          }}
        >
          <FilterMenu
            table="customerOrders"
            searchFieldOptions={searchFieldOptions}
            refetchOrders={handleSearch}
          />
          <CardViewSettingsModal
            dataGridName="customerOrders"
            searchFields={searchFieldOptions}
          />
          {isMobile && (
            <Tooltip title="More options">
              <IconButton
                size="small"
                onClick={handleMenuClick}
                sx={{
                  bgcolor: Boolean(anchorEl)
                    ? 'action.selected'
                    : 'transparent',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 3,
          sx: { minWidth: 200 },
        }}
      >
        <MenuItem
          onClick={() => {
            toggleView('table');
            setSelectedCards([]);
            handleMenuClose();
          }}
        >
          Switch to Table View
        </MenuItem>
      </Menu>
    </Box>
  );
};

const CustomerOrders = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const gridRef = useRef(null);

  const {
    view,
    permissions,
    orders,
    columns,
    searchFields,
    loading,
    errors,
    modals,
    selection,
    handlers,
  } = useCustomerOrdersView();

  const showActionMenu =
    (view === 'card' && selection.selectedCards?.length > 0) ||
    (view !== 'card' && selection.selectedRows?.length > 0);

  return (
    <MainContainer title="Customer Orders">
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {errors.hasError && <Error errorMessage={errorMsg} />}

        <ShippingLabelScanModal
          open={modals.isShippingLabelScanModalOpen}
          handleClose={handlers.handleCloseShippingLabelScanModal}
        />

        {showActionMenu && (
          <ActionMenu
            selectedRows={
              view === 'card' ? selection.selectedCards : selection.selectedRows
            }
            onActionSelect={handlers.onActionSelect}
          />
        )}

        {!!modals.currentModal && (
          <DynamicModal
            onClose={modals.closeModal}
            currentModal={modals.currentModal}
            refetchOrders={handlers.updateOrders}
          />
        )}

        {view === 'card' && (
          <CardViewControls
            view={view}
            toggleView={handlers.toggleView}
            handleSearch={handlers.handleSearch}
            searchFieldOptions={searchFields}
            isMobile={isMobile}
          />
        )}

        {!modals.isScanning && (
          <ContentView
            view={view}
            loading={loading}
            orders={orders.data}
            columns={columns}
            searchFields={searchFields}
            permissions={permissions}
            handlers={handlers}
            selection={selection}
            hasFilter={orders.hasFilter}
            totalRows={orders.totalRows}
            gridRef={gridRef}
          />
        )}
      </Box>
    </MainContainer>
  );
};

export default CustomerOrders;
