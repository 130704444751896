import AppBar from "../../../components/AppBarWithDrawer/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import IconButton from "@mui/material/IconButton";
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import * as React from "react";
import { useCookies } from "react-cookie";
import NotificationsMenu from "./NotificationsMenu";
import { useNavigate } from "react-router-dom";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useRecoilValue } from "recoil";
import { sideBarOpenState } from "../../../atoms/atoms";

export const HeaderBar = ({ open, handleDrawerOpen, title }) => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["_auth_state"]);

  const user =
    JSON.parse(localStorage.getItem("USER")) || cookies["_auth_state"];

  const sideBarOpen = useRecoilValue(sideBarOpenState);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = useSignOut();

  const handleLogout = () => {
    console.log("User clicked log out");
    signOut();
    handleClose();
  };

  return (
    <AppBar
      position="fixed"
      open={open}
      sx={{
        background: "white",
        height: "50px",
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          minHeight: "48px !important",
          pr: { xs: 1, sm: 2 },
          pl: { xs: 2, sm: sideBarOpen ? 2 : 3 },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            minHeight: "48px !important",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#2D353B",
              height: "48px",
              width: open ? "0px" : { xs: "55px", sm: "64px" },
              position: "absolute",
              left: 1,
              top: 0,
              transition: "width 0.3s",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                ...(open && { display: "none" }),
                color: "rgba(197,197,197,0.7)",
                m: "auto",
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Typography
            fontSize={{ xs: 17, sm: 20 }}
            noWrap
            component="div"
            fontWeight="600"
            sx={{ color: "black", ml: open ? 0 : 7 }}
          >
            {title}
          </Typography>
        </Box>
        <Box display="flex">
          <Box mr={1}>
            <NotificationsMenu />
          </Box>
          <Avatar
            onClick={handleClick}
            sx={{
              width: "40px",
              height: "40px",
              ":hover": { cursor: "pointer" },
            }}
          >
            {!user?.avatarImage ? (
              <ImageIcon sx={{ width: "25px", height: "25px" }} />
            ) : (
              <img
                style={{ width: "40px", height: "40px" }}
                src={user.avatarImage}
                alt="User avatar"
              />
            )}
          </Avatar>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={() => navigate('/settings')}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
