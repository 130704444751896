import { Menu, MenuItem } from "@mui/material";
const ContextMenu = ({contextMenu, handleContextMenuClose, handleOpenInNewTab}) => {
    return (
        <Menu
        open={contextMenu !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleOpenInNewTab}>Open in New Tab</MenuItem>
      </Menu>    
    )
}

export default ContextMenu;
