import { Autocomplete, Box, TextField } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-premium";
import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { mainProductsState, orderDetailsState } from "../../../atoms/atoms";

export function AutocompleteEditInputCell(props) {
  const { PID, params, options, freeSolo, getOptionLabel, multiple } = props;
  const apiRef = useGridApiContext();

  const mainProducts = useRecoilValue(mainProductsState);
  const setMainProducts = useSetRecoilState(mainProductsState);

  const state = useRecoilValue(orderDetailsState);

  const editProduct = useCallback(
    (id, values) => {
      setMainProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === id ? { ...product, ...values } : product
        )
      );
    },
    [setMainProducts]
  );

  const handleChange = useCallback(
    (event, newValue) => {
      const productID = params.row.id;
      if (!newValue?.label) return;

      const product = options.find((product) => {
        if (PID) return product.label === newValue.label;
        else return product.description === newValue.description;
      });
      if (!product) return console.error("ERROR: this product does not exist");

      const existingProduct = mainProducts.find(
        (prod) => prod.itemPID === product.label
      );
      // if product PID already exists update qty and delete duplicate
      if (product.label.split("-")[0] !== "CMDH" && !!existingProduct) {
        const newQuantity = existingProduct.quantity + 1;

        const newLinePrice =
          ((existingProduct.linePrice + existingProduct.discount) /
            existingProduct.quantity) *
            newQuantity -
          existingProduct.discount;

        const updatedProduct = {
          ...existingProduct,
          linePrice: newLinePrice,
          salesTaxDollarAmount: newLinePrice * existingProduct.taxRate,
          quantity: newQuantity,
        };

        setMainProducts((prevProducts) =>
          prevProducts
            .map((prod) =>
              prod.id === existingProduct.id ? updatedProduct : prod
            )
            .filter((prod) => prod.id !== productID)
        );
      } else {
        const updatedProduct = {
          ...mainProducts.find((product) => product.id === productID),
          itemPID: product.label,
          description: product.description,
        };
        editProduct(productID, updatedProduct);

        apiRef.current.setEditCellValue({
          id: params.id,
          field: params.field,
          value: PID ? newValue.label : newValue.description,
        });
      }
    },
    [
      options,
      params.id,
      params.field,
      PID,
      mainProducts,
      state.discountPercentage,
      state.isSalesTaxIncluded,
      apiRef,
      editProduct,
      setMainProducts,
    ]
  );

  return (
    <Autocomplete
      value={
        PID
          ? options.find((option) => option.label === params.row?.itemPID) || ""
          : options.find(
              (option) => option.description === params.row?.description
            ) || ""
      }
      onChange={handleChange}
      onInputChange={(event, value) =>
        freeSolo && !multiple && event && handleChange(event, value)
      }
      sx={{ width: "80%", m: "auto" }}
      multiple={multiple}
      options={options ?? []}
      autoHighlight
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (PID) {
          return option.label || "";
        } else {
          return option.description || "";
        }
      }}
      freeSolo={true}
      forcePopupIcon={true}
      renderOption={(p, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...p}>
          {PID ? option.label : option.description} (
          {PID ? option.description : option.label})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
