import { useEffect, useRef, useState } from "react";

import { getFilter } from "../api/Filters";
import { loadFilter, loadSearchTerm } from "../common";
import { buildQueryString } from "../utils/filterUtils";
import {
  getInStockOrders,
  getInventoryOrders,
  getToOrderOrders,
} from "../api/InventoryOrders";

import axios, { isCancel } from "axios";

const useInventoryOrders = (table) => {
  const [orders, setOrders] = useState(null);

  const [ordersLoading, setOrdersLoading] = useState(true);
  const [ordersError, setOrdersError] = useState(null);
  const abortControllerRef = useRef(null);

  const handleOrdersChange = (id, field, value) => {
    setOrders((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const fetchOrders = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.cancel();
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    abortControllerRef.current = source;

    setOrdersLoading(true);
    setOrders([]);

    try {
      const filterId = loadFilter(table);
      const searchTerm = loadSearchTerm(table);

      let filterDetails = filterId ? (await getFilter(filterId)).filter : null;

      if (table === "auditCorrections") {
        filterDetails = filterId
          ? {
              ...filterDetails,
              filters: [
                ...filterDetails.filters,
                {
                  label: "Everywhere",
                  displayName: "Order Type",
                  fieldName: "ordertype",
                  dataType: "string",
                  searchTypes: [
                    "Contains",
                    "Does not contain",
                    "Is one of",
                    "Is not one of",
                    "Is equal to",
                    "Is not equal to",
                    "Is blank",
                    "Is not blank",
                  ],
                  type: 4,
                  value: "Audit Correction",
                  error: false,
                },
              ],
            }
          : {
              filters: [
                {
                  label: "Everywhere",
                  displayName: "Order Type",
                  fieldName: "ordertype",
                  dataType: "string",
                  searchTypes: [
                    "Contains",
                    "Does not contain",
                    "Is one of",
                    "Is not one of",
                    "Is equal to",
                    "Is not equal to",
                    "Is blank",
                    "Is not blank",
                  ],
                  type: 4,
                  value: "Audit Correction",
                  error: false,
                },
              ],
            };
      }
      if (table === "stockOrders") {
        filterDetails = filterId
          ? {
              ...filterDetails,
              filters: [
                ...filterDetails.filters,
                {
                  label: "Everywhere",
                  displayName: "Order Type",
                  fieldName: "ordertype",
                  dataType: "string",
                  searchTypes: [
                    "Contains",
                    "Does not contain",
                    "Is one of",
                    "Is not one of",
                    "Is equal to",
                    "Is not equal to",
                    "Is blank",
                    "Is not blank",
                  ],
                  type: 5,
                  value: "Audit Correction",
                  error: false,
                },
              ],
            }
          : {
              filters: [
                {
                  label: "Everywhere",
                  displayName: "Order Type",
                  fieldName: "ordertype",
                  dataType: "string",
                  searchTypes: [
                    "Contains",
                    "Does not contain",
                    "Is one of",
                    "Is not one of",
                    "Is equal to",
                    "Is not equal to",
                    "Is blank",
                    "Is not blank",
                  ],
                  type: 5,
                  value: "Audit Correction",
                  error: false,
                },
              ],
            };
      }
      const andOr = filterDetails?.andOr || "and";
      const fetchQuery = buildQueryString(searchTerm, filterDetails, andOr);
      let orders;
      if (table === "currentStock") {
        orders = await getInStockOrders(fetchQuery, source.token);
      } else if (table === "stockPredictions") {
        orders = await getToOrderOrders(fetchQuery, source.token);
      } else if (table === "stockOrders" || table === "auditCorrections") {
        orders = await getInventoryOrders(fetchQuery, source.token);
      }
      if (source.token.reason) {
        // Check if the request was canceled before continuing
        console.log("Fetch canceled:", source.token.reason.message);
        return;
      }
      setOrders(orders.results);
    } catch (err) {
      if (isCancel(err)) {
        console.log("Request canceled:", err.message);
      } else {
        console.error("Error fetching orders:", err);
        setOrdersError(
          "An error occurred while fetching inventory orders. Please try again later."
        );
        setOrdersLoading(false);
      }
    } finally {
      if (!source.token.reason) {
        // Only set loading to false if the requests were not canceled
        setOrdersLoading(false);
      }
    }
  };

  useEffect(() => {
    setOrdersLoading(true);

    fetchOrders();
  }, [table]);

  return {
    orders,
    ordersLoading: ordersLoading && !!orders,
    ordersError,
    refetch: fetchOrders,
    handleOrdersChange: handleOrdersChange,
  };
};

export default useInventoryOrders;
