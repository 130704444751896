import { useCallback, useEffect, useRef, useState } from "react";
import { setupColumns } from "../utils/setupColumns";
import { getColumnSettings, updateColumnSettings } from "../../../api/tableSettings";

const saveSettings = (settings, dataGridName, saveTimeoutRef) => {
  if (saveTimeoutRef.current) {
    clearTimeout(saveTimeoutRef.current);
  }

  saveTimeoutRef.current = setTimeout(async () => {
    try {
      await updateColumnSettings(dataGridName, settings);
    } catch (error) {
      console.error("Error saving column settings:", error);
    }
  }, 1000);
};

export const useColumnManagement = (props, apiRef, gridColumns) => {
  const {
    tableName,
    searchFields,
    role,
    dateFormat,
    onRowClick,
    productPIDs,
    handleOpenDeleteModal,
    openModal,
    navigate,
    handleRowSelection,
    handleMenuClick,
    groupingModel,
  } = props;

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [pinnedColumnsLeft, setPinnedColumnsLeft] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isPinned, setIsPinned] = useState(true);

  const isInitialized = useRef(false);
  const handleUnpinAllColumnsRef = useRef(false);
  const settingsRef = useRef(null);
  const saveTimeoutRef = useRef(null);

  // Create debounced save function
  const saveColumnSettings = useCallback(() => {
    if (!isInitialized.current) return;

    const columnProperties = gridColumns.current.map((col) => ({
      field: col.field,
      width: col.width,
      isPinned: pinnedColumnsLeft?.includes(col.field),
      isHidden: !columnVisibilityModel[col.field],
    }));

    const newSettings = {
      columnProperties,
      visibilityModel: columnVisibilityModel,
      pinnedColumns: pinnedColumnsLeft,
      pinState: isPinned,
      columnOrder: gridColumns.current.map((col) => col.field),
    };

    // Only save if settings have changed
    if (JSON.stringify(settingsRef.current) !== JSON.stringify(newSettings)) {
      settingsRef.current = newSettings;

      // Clear existing timeout
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }

      // Set new timeout
      saveTimeoutRef.current = setTimeout(async () => {
        try {
          await updateColumnSettings(tableName, newSettings);
        } catch (error) {
          console.error("Error saving column settings:", error);
        }
      }, 1000); // 1 second delay
    }
  }, [tableName, columnVisibilityModel, pinnedColumnsLeft, isPinned]);

  // Handle cleanup
  useEffect(() => {
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, []);

  // Handle mobile view
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 600px)");
    setIsMobileView(mediaQuery.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobileView(event.matches);
    };

    mediaQuery.addListener(handleMediaQueryChange);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  // Load column settings and initialize
  useEffect(() => {
    if (!searchFields || isInitialized.current) return;

    const initializeColumns = async () => {
      try {
        setLoading(true);
        const savedSettings = await getColumnSettings(tableName);
        settingsRef.current = savedSettings?.settings;

        const { newGridColumns, newColumnVisibilityModel, newPinnedLeft } = setupColumns({
          searchFields,
          tableName,
          role,
          dateFormat,
          onRowClick,
          productPIDs,
          handleOpenDeleteModal,
          openModal,
          navigate,
          handleMenuClick,
          savedSettings: savedSettings?.settings,
        });

        const initialPinnedColumns = [];
        if (savedSettings?.settings?.pinState ?? true) {
          if (handleRowSelection) {
            initialPinnedColumns.push("selectable");
          }
          newPinnedLeft.forEach((column) => {
            if (!initialPinnedColumns.includes(column)) {
              initialPinnedColumns.push(column);
            }
          });
        }

        gridColumns.current = newGridColumns;
        setColumnVisibilityModel(newColumnVisibilityModel);
        setPinnedColumnsLeft(initialPinnedColumns);
        setIsPinned(savedSettings?.settings?.pinState ?? true);

        isInitialized.current = true;
      } catch (error) {
        console.error("Error initializing columns:", error);
        isInitialized.current = true;
      } finally {
        setLoading(false);
      }
    };

    initializeColumns();
  }, [tableName, searchFields]);

  const createSettings = useCallback(
    (overrides = {}) => {
      const columnProperties = gridColumns.current.map((col) => ({
        field: col.field,
        width: col.width,
        isPinned:
          overrides.pinState && overrides.pinnedColumns
            ? overrides.pinnedColumns.includes(col.field)
            : col.isPinned,
        isHidden: !(overrides.visibilityModel || columnVisibilityModel)[col.field],
      }));

      return {
        columnProperties,
        visibilityModel: overrides.visibilityModel || columnVisibilityModel,
        pinnedColumns: overrides.pinnedColumns || pinnedColumnsLeft,
        pinState: overrides.pinState ?? isPinned,
        columnOrder: gridColumns.current.map((col) => col.field),
      };
    },
    [columnVisibilityModel, pinnedColumnsLeft, isPinned]
  );

  const handlePinnedColumnsChange = useCallback(
    (updatedPinnedColumns) => {
      if (handleUnpinAllColumnsRef.current) {
        handleUnpinAllColumnsRef.current = false;
        return;
      }

      const uniqueLeft = [...new Set(updatedPinnedColumns.left)];
      const settings = createSettings({
        pinnedColumns: uniqueLeft,
        pinState: uniqueLeft.length > 0,
      });

      saveSettings(settings, tableName, saveTimeoutRef);
      setPinnedColumnsLeft(uniqueLeft);
      setIsPinned(uniqueLeft.length > 0);
    },
    [tableName, createSettings]
  );

  const handleColumnOrderChange = useCallback(
    (params) => {
      let { oldIndex, targetIndex } = params;

      // Adjust indices to account for the checkbox selection column if present
      if (handleRowSelection) {
        oldIndex -= 1;
        targetIndex -= 1;
      }

      // Adjust indices to account for grouped columns that appear at the start
      if (groupingModel) {
        oldIndex -= 1;
        targetIndex -= 1;
      }

      const newColumns = [...gridColumns.current];

      const [reorderedColumn] = newColumns.splice(oldIndex, 1);
      newColumns.splice(targetIndex, 0, reorderedColumn);
      gridColumns.current = newColumns;
      saveColumnSettings();
    },
    [handleRowSelection, saveColumnSettings]
  );

  // Handle column width change
  const handleColumnWidthChange = useCallback(
    (params) => {
      const isGroupColumn = params.colDef.field === "__row_group_by_columns_group__";
      let index;

      isGroupColumn
        ? (index = gridColumns.current.findIndex(
            (col) => col.displayName === params.colDef.headerName
          ))
        : (index = gridColumns.current.findIndex((col) => col.field === params.colDef.field));

      if (index !== -1) {
        gridColumns.current[index].width = params.colDef.width;
        saveColumnSettings();
      }
    },
    [saveColumnSettings]
  );

  // Handle column visibility change
  const handleColumnVisibilityModelChange = useCallback(
    (newModel) => {
      const settings = createSettings({ visibilityModel: newModel });
      saveSettings(settings, tableName, saveTimeoutRef);
      setColumnVisibilityModel(newModel);
    },
    [tableName, createSettings]
  );

  // Handle unpinning all columns
  const handleUnpinAllColumns = useCallback(() => {
    handleUnpinAllColumnsRef.current = true;
    const settings = createSettings({ pinnedColumns: [], pinState: false });
    saveSettings(settings, tableName, saveTimeoutRef);
    setPinnedColumnsLeft([]);
    setIsPinned(false);
    apiRef.current.setPinnedColumns({ left: [], right: ["actions"] });
  }, [apiRef, tableName, createSettings]);

  // Handle pinning all columns
  const handlePinAllColumns = useCallback(() => {
    const columnsToPinned = gridColumns.current
      .filter((col) => col.isPinned)
      .map((col) => col.field);

    const updatedPinnedColumns = handleRowSelection
      ? ["selectable", ...columnsToPinned]
      : columnsToPinned;

    const settings = createSettings({
      pinnedColumns: updatedPinnedColumns,
      pinState: true,
    });

    saveSettings(settings, tableName, saveTimeoutRef);
    setPinnedColumnsLeft(updatedPinnedColumns);
    setIsPinned(true);
    apiRef.current.setPinnedColumns({
      left: updatedPinnedColumns,
      right: ["actions"],
    });
  }, [apiRef, handleRowSelection, tableName, createSettings]);

  // Update pinned columns in API ref when they change
  useEffect(() => {
    if (apiRef.current && pinnedColumnsLeft) {
      apiRef.current.setPinnedColumns({
        left: pinnedColumnsLeft,
        right: ["actions"],
      });
    }
  }, [apiRef]);

  return {
    columnVisibilityModel,
    pinnedColumnsLeft,
    isPinned,
    isMobileView,
    loading,
    handleUnpinAllColumns,
    handlePinAllColumns,
    onPinnedColumnsChange: handlePinnedColumnsChange,
    onColumnOrderChange: handleColumnOrderChange,
    onColumnVisibilityModelChange: handleColumnVisibilityModelChange,
    onColumnWidthChange: handleColumnWidthChange,
  };
};
