import React, { useCallback, useEffect, useState } from "react";
import Drawer from "../../../components/AppBarWithDrawer/Drawer";
import DrawerHeader from "../../../components/AppBarWithDrawer/DrawerHeader";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import { Link } from "./Link";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AssignmentIcon from "@mui/icons-material/Assignment";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import PersonIcon from "@mui/icons-material/Person";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import QrCodeIcon from "@mui/icons-material/QrCode";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useRecoilValue } from "recoil";
import { permissionsState } from "../../../atoms/atoms";
import { useLocation, useNavigate } from "react-router-dom";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import TocIcon from "@mui/icons-material/Toc";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FactoryIcon from "@mui/icons-material/Factory";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import BuildIcon from "@mui/icons-material/Build";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

const images = require.context("../../../images/general", true, /\.svg$/);
const imageSrc = images("./Commercial-Dehydrator-neg-type.svg");

const ConditionalWrapper = ({ children }) => {
  const checkVisibleChildren = useCallback((children) => {
    return React.Children.toArray(children).some((child) => {
      if (child.type === Link) {
        return child.props.permissions
          ? Object.values(child.props.permissions).some(Boolean)
          : true;
      }
      if (child.props && child.props.children) {
        return checkVisibleChildren(child.props.children);
      }
      return false;
    });
  }, []);

  const [hasVisibleChildren, setHasVisibleChildren] = useState(() => {
    return checkVisibleChildren(children);
  });

  useEffect(() => {
    setHasVisibleChildren(checkVisibleChildren(children));

    const observer = new MutationObserver(() => {
      setHasVisibleChildren(checkVisibleChildren(children));
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, [children, checkVisibleChildren]);

  return hasVisibleChildren ? children : null;
};

export const NavigationDrawer = ({ open, handleDrawerClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const signOut = useSignOut();
  const permissions = useRecoilValue(permissionsState);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [expandedSections, setExpandedSections] = useState({
    inventory: location.pathname.startsWith("/inventoryOrders"),
    databases: location.pathname.startsWith("/database"),
    "scanning and offline":
      location.pathname.startsWith("/order") ||
      location.pathname.startsWith("/offlineOrders"),
  });

  const navbarMainBackgroundColour =
    process.env.REACT_APP_API_ORDER_LINK_URL?.includes("mclellanhill-gcp")
      ? "#3f4b52"
      : process.env.REACT_APP_DEPLOYMENT_SIDEBAR_COLOR || "#6c2929";

  const navbarSecondaryBackgroundColour =
    process.env.REACT_APP_API_ORDER_LINK_URL?.includes("mclellanhill-gcp")
      ? "#2d353b"
      : process.env.REACT_APP_DEPLOYMENT_SIDEBAR_COLOR || "#2d353b";

  const handleNavigate = (url, isModal) => {
    if (matchesSM) handleDrawerClose();
    if (isModal) {
      navigate(`/customerOrders?modal=shippingLabelScan`);
    } else {
      navigate(url);
    }
  };

  const handleSectionToggle = (section) => {
    setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const renderNavSection = (item, index, items) => {
    const nextItemIsAccordion =
      index < items?.length - 1 && !!items[index + 1].children;
    const linkColour = "rgb(197,197,197)";

    if (item.children) {
      return (
        <ConditionalWrapper key={"wrap_" + item.header}>
          <Box>
            {!open && <Divider sx={{ background: "white", my: 1 }} />}
            <ListItemButton
              onClick={() => handleSectionToggle(item.header.toLowerCase())}
              sx={{
                px: 2.5,
                py: open ? 1 : 2,
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <ListItemIcon
                sx={{
                  color: linkColour,
                  minWidth: 0,
                  mr: open ? 1.6 : "auto",
                  justifyContent: "center",
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.header}
                sx={{
                  color: linkColour,
                  display: open ? "flex" : "none",
                  marginTop: "5px !important",
                  "& .MuiTypography-root": {
                    fontSize: "0.9rem",
                    lineHeight: 0.7,
                  },
                }}
              />
              {open &&
                (expandedSections[item.header.toLowerCase()] ? (
                  <ExpandLess sx={{ color: linkColour }} />
                ) : (
                  <ExpandMore sx={{ color: linkColour }} />
                ))}
            </ListItemButton>
            <Collapse
              in={expandedSections[item.header.toLowerCase()]}
              timeout="auto"
              unmountOnExit
              sx={{
                background: navbarSecondaryBackgroundColour,
              }}
            >
              <List component="div" disablePadding>
                {item.children.map((child, index) => (
                  <Box
                    key={child.header || `NavChild${index}`}
                    my={1}
                    mx={open ? 1 : 0}
                  >
                    <Link
                      open={open}
                      link={child}
                      handleOnClick={() =>
                        handleNavigate(child.url, child.isModal)
                      }
                      permissions={child.permissions}
                      permissionsRequired={!child.alwaysShow}
                      isSubItem
                    />
                  </Box>
                ))}
              </List>
            </Collapse>
            {!open && !nextItemIsAccordion && (
              <Divider sx={{ background: "white", my: 1 }} />
            )}
          </Box>
        </ConditionalWrapper>
      );
    } else {
      return (
        <Link
          open={open}
          link={item}
          handleOnClick={() =>
            item.onClick ? item.onClick() : handleNavigate(item.url)
          }
          permissions={item.permissions}
          permissionsRequired={!item.alwaysShow}
        />
      );
    }
  };

  const mainNavigationItems = [
    {
      header: "Notifications",
      url: "/home",
      icon: <HomeIcon />,
      alwaysShow: true,
    },
    {
      header: "Customer Orders",
      url: "/customerOrders",
      icon: <LocalShippingIcon />,
      permissions: permissions?.customerOrders?.columns,
    },
    {
      header: "Scanning and Offline",
      icon: <AssignmentIcon />,
      children: [
        {
          header: "Offline Orders",
          url: "/offlineOrders",
          icon: <WifiOffIcon />,
          permissions: permissions?.offlineOrders?.orderDetails?.columns,
        },

        {
          header: "Assign Serial Numbers",
          url: "/customerOrders",
          icon: <QrCodeIcon />,
          permissions: permissions?.customerOrders?.columns,
          isModal: true,
        },
      ],
    },

    {
      header: "Accounting",
      url: "/accounting/orderReimbursements/pending",
      icon: <RequestQuoteIcon />,
      permissions:
        permissions?.accounting?.orderReimbursements?.pending?.columns,
    },
    {
      header: "Inventory",
      icon: <FactoryIcon />,
      children: [
        {
          header: "Build Order",
          url: "/inventoryOrders/buildOrder",
          icon: <AddCircleOutlineIcon />,
          permissions: {
            canBuildOrders:
              permissions?.inventoryOrders?.orders?.actions?.canBuildOrders,
          },
        },
        {
          header: "Current Stock",
          url: "/inventoryOrders/currentStock",
          icon: <WarehouseIcon />,
          permissions: permissions?.inventoryOrders?.currentStock?.columns,
        },
        {
          header: "Stock Predictions",
          url: "/inventoryOrders/stockPredictions",
          icon: <EmojiObjectsIcon />,
          permissions: permissions?.inventoryOrders?.stockPredictions?.columns,
        },
        {
          header: "Orders",
          url: "/inventoryOrders/stockOrders",
          icon: <TocIcon />,
          permissions: permissions?.inventoryOrders?.stockOrders?.columns,
        },
        {
          header: "Audit Corrections",
          url: "/inventoryOrders/auditCorrections",
          icon: <FactCheckIcon />,
          permissions: permissions?.inventoryOrders?.stockOrders?.columns,
        },
        {
          header: "Production Priority",
          url: "/inventoryOrders/productionPriority",
          icon: <PriorityHighIcon />,
          permissions: {
            canBuildOrders:
              permissions?.inventoryOrders?.stockOrders?.actions
                ?.canBuildOrders,
          },
        },
      ],
    },
    {
      header: "Dashboards",
      url: "/dashboards",
      icon: <DashboardIcon />,
      permissions:
        permissions?.accounting?.orderReimbursements?.pending?.columns,
      children: [
        {
          header: "Production Priority",
          url: "/inventoryOrders/productionPriority",
          icon: <PriorityHighIcon />,
          permissions: {
            canBuildOrders:
              permissions?.inventoryOrders?.stockOrders?.actions
                ?.canBuildOrders,
          },
        },
        {
          header: "Machine",
          url: "/dashboards/machines",
          icon: <PrecisionManufacturingIcon />,
          permissions:
            permissions?.accounting?.orderReimbursements?.pending?.columns,
        },
        {
          header: "Accessories",
          url: "/dashboards/accessories",
          icon: <BuildIcon />,
          permissions:
            permissions?.accounting?.orderReimbursements?.pending?.columns,
        },
      ],
    },
    // {
    //   header: "Databases",
    //   icon: <StorageIcon />,
    //   children: [
    //     {
    //       header: "Products",
    //       url: "/database/products",
    //       icon: <InventoryIcon />,
    //       alwaysShow: true,
    //     },
    //   ],
    // },
    {
      header: "Staff & Roles",
      url: "/iam",
      icon: <PersonIcon />,
      permissions: {
        canAccess:
          permissions?.name === "Super Admin" ||
          permissions?.general?.addOthers,
      },
    },
    { kind: "divider" },
  ];

  const bottomNavigationItems = [
    {
      header: "Settings",
      url: "/settings",
      icon: <SettingsIcon />,
      alwaysShow: true,
    },
    {
      header: "Log out",
      icon: <LogoutIcon />,
      url: "/login",
      onClick: () => {
        console.log("User clicked log out");
        signOut();
      },
      alwaysShow: true,
    },
  ];

  return (
    <Drawer
      variant={"permanent"}
      open={open}
      sx={{
        ...(matchesSM &&
          open && {
            zIndex: 10000,
            position: "absolute",
            width: "100vw",
            height: "100vh",
            ".MuiDrawer-paper": {
              width: "100%",
            },
          }),
      }}
    >
      <DrawerHeader
        sx={{
          minHeight: "40px !important",
          backgroundColor: "#2D353B",
          borderBottom: "rgba(0, 0, 0, 0.12) 1px solid",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          py: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            my: 0.5,
            mr: { xs: 0, sm: "15px" },
            maxWidth: "180px",
          }}
        >
          <img
            style={{
              width: "100%",
              padding: "10px",
            }}
            src={imageSrc}
            alt="Commercial Dehydrators Main Logo"
          />
        </Box>

        <IconButton
          onClick={handleDrawerClose}
          sx={{
            position: "absolute",
            right: 0,
          }}
        >
          {theme.direction === "rtl" ? (
            <ChevronRightIcon sx={{ color: "white" }} />
          ) : (
            <ChevronLeftIcon sx={{ color: "white" }} />
          )}
        </IconButton>
      </DrawerHeader>

      <List
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: navbarMainBackgroundColour,
          py: 2,
        }}
      >
        <Box>
          {mainNavigationItems.map((item, index) => (
            <Box key={item.header || `NavItem${index}`} mb={1.5}>
              {renderNavSection(item, index, mainNavigationItems)}
            </Box>
          ))}
        </Box>
        <Box>
          <Divider sx={{ background: "white", my: 1 }} />
          {bottomNavigationItems.map((item, index) => (
            <Box key={item.header} my={1}>
              {renderNavSection(item, index, bottomNavigationItems)}
            </Box>
          ))}
          <Typography
            color="lightgrey"
            sx={{
              fontFamily: "system-ui",
              fontSize: "0.7em",
              whiteSpace: "pre-wrap",
              maxHeight: "82px",
              overflow: "auto",
              px: 2,
              py: 1,
            }}
          >
            {!process.env.REACT_APP_API_ORDER_LINK_URL?.includes(
              "mclellanhill-gcp"
            ) && process.env.REACT_APP_LAST_COMMIT_INFO}
          </Typography>
        </Box>
      </List>
    </Drawer>
  );
};
