import MainContainer from "../../../../layouts/main";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getImage } from "../../../../components/Datagrid/utils";
import { getToOrderOrders } from "../../../../api/InventoryOrders";
import Loading from "../../../../components/Loading";
import { useError } from "../../../../contexts/ErrorContext";
import ContainerVisualization from "../../../../components/FreightFiller";
import { useNavigate } from "react-router-dom";

const PriorityDashboard = () => {
  const navigate = useNavigate();
  const { showError } = useError();
  const [priorityData, setPriorityData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPriorityData = async () => {
      getToOrderOrders()
        .then((rsp) => {
          setLoading(false);
          setPriorityData(rsp.results.filter((item) => item.needtoorder > 0));
        })
        .catch((err) => {
          console.log(err);
          showError(err.message);
          setLoading(false);
        });
    };
    fetchPriorityData();
  }, []);

  const handleClick = (warehouse) => {
    localStorage.setItem("selectedWarehouse", warehouse);
    localStorage.setItem("lastSelectedContainerSize", "40");
    navigate("/inventoryOrders/buildOrder");
  };

  const groupByWarehouse = (data) => {
    return (
      data?.reduce((acc, item) => {
        if (!acc[item.warehouse]) {
          acc[item.warehouse] = [];
        }
        acc[item.warehouse].push(item);
        return acc;
      }, {}) || {}
    );
  };

  const getWarehouseNeedToOrder = (items) => {
    return items.reduce((sum, item) => sum + item.needtoordervolume, 0);
  };

  const groupedByWarehouse = groupByWarehouse(priorityData || []);

  const sortedWarehouses = Object.entries(groupedByWarehouse).sort((a, b) => {
    const aNeedToOrder = getWarehouseNeedToOrder(a[1]);
    const bNeedToOrder = getWarehouseNeedToOrder(b[1]);
    return bNeedToOrder - aNeedToOrder;
  });

  return (
    <MainContainer title={"Production Priority"}>
      {loading && <Loading sx={{ mx: "auto", width: 40, mt: 4 }} />}
      {!loading && (
        <Paper
          sx={{
            border: 0,
            backgroundColor: "white",
            p: 3,
            height: "calc(100vh - 50px)",
            overflow: "hidden",
          }}
        >
          <Box mx="auto" width={455} sx={{ mb: 4 }}>
            <ContainerVisualization
              volumeFieldName="needtoordervolume"
              quantitiyFieldName="needtoorder"
              containerType={40}
              rows={priorityData}
              isTotalVolume
            />
          </Box>

          <Grid
            container
            spacing={3}
            sx={{
              mt: 1,
              overflow: "auto",
              px: 1,
              pb: 1,
            }}
          >
            {sortedWarehouses.map((item, index) => {
              const warehouse = item[0];
              const orders = item[1];

              const imageUrl = getImage(
                `Flag-${warehouse.slice(0, 2)}-to-${warehouse.slice(0, 2)}.webp`
              );

              return (
                <Grid
                  key={warehouse}
                  item
                  xs={12}
                  md={4}
                  sx={{ height: "auto" }}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      p: 2,
                      py: 3,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 2,
                      height: "100%",
                      borderRadius: 2,
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        transform: "translateY(-2px)",
                      },
                    }}
                  >
                    {/* Priority Badge */}
                    <Box
                      sx={{
                        backgroundColor: "primary.main",
                        color: "white",
                        borderRadius: "20px",
                        px: 2.5,
                        py: 0.5,
                        mb: 1,
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      }}
                    >
                      <Typography variant="subtitle2" fontWeight="600">
                        Priority {index + 1}
                      </Typography>
                    </Box>

                    {/* Warehouse Info */}
                    <Box
                      textAlign="center"
                      sx={{
                        mb: 1,
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="h5"
                        fontWeight="600"
                        sx={{ mb: 0.5 }}
                      >
                        {warehouse}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{
                          letterSpacing: "0.5px",
                          textTransform: "uppercase",
                        }}
                      >
                        {warehouse.slice(0, 2)}
                      </Typography>
                    </Box>

                    {/* Flag Image */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        my: 1,
                      }}
                    >
                      <img
                        style={{
                          width: "120px",
                          height: "auto",
                          objectFit: "contain",
                          filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.1))",
                        }}
                        src={imageUrl}
                        loading="lazy"
                        alt={`${warehouse} flag`}
                      />
                    </Box>

                    {/* Container Visualization */}
                    <Box sx={{ width: "80%", mx: "auto", my: 1 }}>
                      <ContainerVisualization
                        volumeFieldName="needtoordervolume"
                        quantitiyFieldName="needtoorder"
                        containerType={40}
                        rows={orders}
                        isTotalVolume
                      />
                    </Box>

                    {/* Action Button */}
                    <Button
                      variant="contained"
                      onClick={() => handleClick(warehouse)}
                      sx={{
                        mt: "auto",
                        px: 4,
                        py: 1,
                        borderRadius: "8px",
                        textTransform: "none",
                        fontWeight: 600,
                        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                        "&:hover": {
                          boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
                        },
                      }}
                    >
                      Place Order
                    </Button>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      )}
    </MainContainer>
  );
};

export default PriorityDashboard;
