import React, { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useRecoilValue } from "recoil";
import { permissionsState } from "../../atoms/atoms";
import { StyledDataGrid } from "./StyledDataGrid";
import { CustomFooter } from "./components/CustomFooter";
import { getRowClassName } from "./utils/utils";
import { useColumnManagement } from "./hooks/useColumnManagement";
import { useRowSelection } from "./hooks/useRowSelection";
import { useGridInitialization } from "./hooks/useGridInitialization";
import { useContextMenu } from "./hooks/useContextMenu";
import { useInfiniteScroll } from "./hooks/useInfiniteScroll";
import { useSelectionCheck } from "./hooks/useSelectionCheck";
import { isGroupRow } from "../../utils/mui";
import { useDateFormat } from "../../contexts/DateFormatContext";
import { DataGridHeader } from "./components/DataGridHeader";
import Loading from "../Loading";
import ContextMenu from "./components/ContextMenu";

export default function DataGrid(props) {
  const {
    tableName,
    dataGridRef,
    rows,
    handleRowSelection,
    rowID,
    loading,
    hasFilter,
    onRowsScrollEnd,
    totalRows,
    handleSelectionCheck,
    selectedWarehouse,
    onRowClick,
    apiRef: externalApiRef,
    getDetailPanelContent,
    getDetailPanelHeight,
    noBorder,
    borderTop,
    ...otherProps
  } = props;

  const role = useRecoilValue(permissionsState);
  const gridColumns = useRef([]);
  const internalApiRef = useGridApiRef();
  const apiRef = externalApiRef || internalApiRef;
  const { dateFormat } = useDateFormat();
  const navigate = useNavigate();

  const { isInitialized, handleGridReady } = useGridInitialization(
    tableName,
    dataGridRef,
    apiRef
  );

  const { columnVisibilityModel, pinnedColumnsLeft, isPinned, ...columnManagement } =
    useColumnManagement({ ...props, role, navigate, dateFormat }, apiRef, gridColumns);

  const checkSelectionAllowed = useSelectionCheck(handleSelectionCheck, selectedWarehouse);

  const { rowSelectionModel, isRowSelectable, handleRowSelectionModelChange, columns } =
    useRowSelection(
      {
        ...props,
        handleSelectionCheck: checkSelectionAllowed,
      },
      apiRef,
      gridColumns
    );

  const {  
    contextMenu,
    handleContextMenu,
    handleOpenInNewTab,
    handleContextMenuClose 
  } = useContextMenu(tableName, rows, rowID);


  const { infiniteScrollProps } = useInfiniteScroll(hasFilter, onRowsScrollEnd, totalRows, rows);


  const getRowHeight = React.useCallback((params) => {
    return isGroupRow(params.model) ? 50 : 85;
  }, []);

  const handleRowClick = useCallback(
    (params, event) => {
      if (event.target.tagName !== "A") {
        if (getDetailPanelContent) {
          apiRef.current.toggleDetailPanel(params.id);
        } else if (onRowClick) {
          onRowClick(params.row, event);
        }
      }
    },
    [apiRef, onRowClick, getDetailPanelContent]
  );

  const handleCellClick = useCallback((params, event) => {
    if (params.field === "__check__" || params.field === "selectable") {
      event.stopPropagation();
      return;
    }
  }, []);

  if (gridColumns.current?.length === 0 || !columnVisibilityModel || !pinnedColumnsLeft) {
    return <Loading sx={{ width: 40, mx: "auto", mt: 3 }} />;
  }

  return (
    <>
    <StyledDataGrid
      sx={{
        borderWidth: noBorder ? 0 : 1,

        borderTopWidth: borderTop ? 1 : 0,
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
          display: "none",
        },
      }}
      columns={columns()}
      rows={rows}
      apiRef={apiRef}
      loading={loading}
      getRowId={(row) => row[rowID]}
      getRowClassName={getRowClassName}
      getRowHeight={props.getRowHeight || getRowHeight}
      onStateChange={handleGridReady}
      columnVisibilityModel={columnVisibilityModel}
      rowSelectionModel={rowSelectionModel}
      isRowSelectable={isRowSelectable}
      onRowSelectionModelChange={handleRowSelectionModelChange}
      onCellClick={handleCellClick}
      onRowClick={handleRowClick}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={getDetailPanelHeight}
      slots={{
        toolbar: props.componentProps?.toolbar && DataGridHeader,
        footer: props.footer || CustomFooter,
      }}
      slotProps={{
        toolbar: {
          componentProps: props.componentProps?.toolbar,
          handleUnpinAllColumns: columnManagement.handleUnpinAllColumns,
          handlePinAllColumns: columnManagement.handlePinAllColumns,
          isPinned,
          tableName,
          searchFields: props.searchFields,
          handleSearch: props.handleSearch,
        },
        row: {
          onContextMenu: handleContextMenu,
        },
      }}
      initialState={{
        rowGrouping: {
          model: props.groupingModel,
        },
        pinnedColumns: {
          left: pinnedColumnsLeft,
          right: ["actions"],
        },
        detailPanel: {
          expandedRowIds: [],
        },
      }}
      experimentalFeatures={{ rowGrouping: true, detailPanel: true }}
      disableColumnFilter
      checkboxSelection={!!handleRowSelection && props.groupingModel}
      disableRowSelectionOnClick={!getDetailPanelContent}
      disableSelectionOnClick={!getDetailPanelContent}
      sortingMode="client"
      filterMode="server"
      onPinnedColumnsChange={columnManagement.onPinnedColumnsChange}
      onColumnWidthChange={columnManagement.onColumnWidthChange}
      onColumnOrderChange={columnManagement.onColumnOrderChange}
      onColumnVisibilityModelChange={columnManagement.onColumnVisibilityModelChange}
      {...infiniteScrollProps}
      {...otherProps}
    />
    <ContextMenu
      contextMenu={contextMenu}
      handleContextMenuClose={handleContextMenuClose}
      handleOpenInNewTab={handleOpenInNewTab}
    />
    </>
  );
}
