import React from "react";
import CreateInventoryDetails from "./createInventoryDetails";
import CreateInventoryTable from "./createInventoryTable";
import WarehouseTransfer from "./warehouseTransfer";
import CreateAuditCorrection from "./createAuditCorrection";
import ContainerSizeSelection from "./containerSizeSelection";
import EditInventoryOrder from "./editInventoryOrder";
import ShipInventory from "./shipInventory";
import ReceiveInventory from "./receiveInventory";
import UpdateStatus from "./updateStatus";
import AddCostsByItem from "./addCostsByItem";
import DistributeOrderCosts from "./distributeOrderCosts";
import MarkAsPaid from "./markAsPaid";
import AddMachineSpecs from "./addMachineSpecs";
import AddNotes from "./addNotes";
import EditAuditCorrection from "./editAuditCorrection";

export const getModalContent = (currentModal, page, isNotBlank) => {
  const contentMap = {
    newInventoryOrder: {
      1: <CreateInventoryDetails data={currentModal?.data} />,
      2: <ContainerSizeSelection onNext={() => {}} />,
      3: <CreateInventoryTable data={{ ...currentModal?.data, isNotBlank }} />,
    },
    warehouseTransfer: <WarehouseTransfer data={currentModal?.data} />,
    createAuditCorrection: <CreateAuditCorrection data={currentModal?.data} />,
    editInventoryOrder: <EditInventoryOrder data={currentModal?.data} />,
    shipInventory: <ShipInventory data={currentModal?.data} />,
    receiveInventory: <ReceiveInventory data={currentModal?.data} />,
    updateStatus: <UpdateStatus data={currentModal?.data} />,
    addCostsByItem: <AddCostsByItem data={currentModal?.data} />,
    distributeOrderCosts: <DistributeOrderCosts data={currentModal?.data} />,
    markAsPaid: <MarkAsPaid data={currentModal?.data} />,
    addMachineSpecs: <AddMachineSpecs data={currentModal?.data} />,
    addNotes: <AddNotes data={currentModal?.data} />,
    editAuditCorrection: <EditAuditCorrection data={currentModal?.data} />,
  };

  if (currentModal?.type === "newInventoryOrder") {
    return contentMap[currentModal.type][page];
  }
  return contentMap[currentModal?.type] || null;
};
