import { useCallback } from 'react';

export const useSelectionCheck = (handleSelectionCheck, selectedWarehouse) => {
  return useCallback((params) => {
    if (!handleSelectionCheck) return false;
    
    return params.row.shippingstatus === "DELIVERED" ||
      params.row["orderpaymentstatus"] === "NOT_PAID" ||
      params.row.canceled ||
      (selectedWarehouse && selectedWarehouse !== params.row.warehouse);
  }, [handleSelectionCheck, selectedWarehouse]);
}; 