import axios from "./axios";
import { API_ORDER_LINK_URL } from "../consts";
import { PERMISSION_PATH_MAP } from "../consts/permissions";

function createFilter(filter) {
  return axios
    .post(`${API_ORDER_LINK_URL}/filters`, { filter: filter })
    .then((response) => response.data);
}

function deleteFilter(filterID, dataGridName) {
  let permissionPath = PERMISSION_PATH_MAP[dataGridName]?.join(".");
  return axios
    .delete(
      `${API_ORDER_LINK_URL}/filters?filterID=${filterID}&permissionPath=${permissionPath}`
    )
    .then((response) => response.data);
}

function getAllFilters(dataGridName) {
  let permissionPath = PERMISSION_PATH_MAP[dataGridName]?.join(".");
  return axios
    .get(
      `${API_ORDER_LINK_URL}/filters?dataGridName=${dataGridName}&permissionPath=${permissionPath}`
    )
    .then((response) => response.data);
}

function getFilter(filterID) {
  return axios
    .get(`${API_ORDER_LINK_URL}/filters?filterID=${filterID}`)
    .then((response) => response.data);
}

function updateFilter(filterID, updatedFilter, dataGridName) {
  let permissionPath = PERMISSION_PATH_MAP[dataGridName].join(".");
  return axios
    .patch(`${API_ORDER_LINK_URL}/filters`, {
      filterID,
      updatedFilter,
      dataGridName,
      permissionPath,
    })
    .then((response) => response.data);
}

export { createFilter, deleteFilter, getFilter, getAllFilters, updateFilter };
