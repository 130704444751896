import React from "react";
import { Box } from "@mui/material";
import CardView from "../../../components/CardView";
import Loading from "../../../components/Loading";

const OrdersCardView = ({ orders, columns, loading }) => {
  if (loading) {
    return (
      <Box width="40px" mx="auto" mt={4}>
        <Loading />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: { xs: "calc(100% - 100px)", sm: "calc(100% - 100px)" },
        width: "100%",
      }}
    >
      <CardView
        table="orderUnit"
        dataGridName="customerOrders"
        data={orders}
        searchFields={columns}
        loading={loading}
        defaultColumnHeader={{
          fieldName: "shippingstatus",
          dataType: "string",
          label: "Shipping Status",
        }}
      />
    </Box>
  );
};

export default React.memo(OrdersCardView);
