import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import dayjs from 'dayjs';
import {jwtDecode} from 'jwt-decode';
import Cookies from "js-cookie";
import { authSoftExpiryBufferMilliseconds } from "../../common";
import AuthSoftExpiryModal from "./AuthSoftExpiryModal";


// Only sends people to login page if they're not authenticated
const AuthSoftExpiryCheck = () => {
  const isAuthenticated = useIsAuthenticated();
  const authHeader = useAuthHeader();
  const navigate = useNavigate();
  const timeoutIdRef = useRef(null); // Use useRef to store the timeoutId
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!authHeader || !isAuthenticated){
      return;
    }

    // Strip 'Bearer ' from the authHeader to get the token
    const token = authHeader?.split(' ')[1];
    if (!token) return;

    try {
      const timeMsUntilExpiry = getMsUntilTokenExpiry(authHeader);

      // Clear any previous timeout if already set
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      // Set a timeout to trigger just after the token's soft expiry date
      let softExpiryMs = timeMsUntilExpiry - authSoftExpiryBufferMilliseconds;
      let timeoutMs = Math.max(softExpiryMs, 6000); // wait at least 6 seconds, to avoid bugs
      const newTimeoutId = setTimeout(() => {
        try {
          // Double check if the user is still authenticated and near expiry
          const timeUntilExpiry = getMsUntilTokenExpiry(authHeader);
          let isSoftExpired = timeUntilExpiry < authSoftExpiryBufferMilliseconds;
          console.log(`AuthSoftExpiryCheck setTimeout check: isAuthd ${isAuthenticated}, valid ${timeUntilExpiry}ms, soft ${softExpiryMs}ms, isSoftExpired? ${isSoftExpired}`)
          if (isAuthenticated && isSoftExpired) {
            // Show alert
            setIsModalOpen(true)
          }
        } catch (e) {
          console.error("AuthSoftExpiryCheck setTimeout error:  " + e.stack)
        }
      }, timeoutMs);

      timeoutIdRef.current = newTimeoutId;
      console.log(`AuthSoftExpiryCheck: setTimeout check in ${Math.round(timeoutMs / 1000 / 60 * 100) / 100} mins`)
    } catch (error) {
      console.error('AuthSoftExpiryCheck: Error decoding JWT or setting timer', error);
    }

    // Cleanup timeout on component unmount or authHeader change
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [authHeader, isAuthenticated]);


  let handleClose = () => {
    // Double check using the cookie, in case they've logged in in another tab before dismissing the alert
    const cookieToken = Cookies.get("_auth");
    const timeUntilExpiryCookie = cookieToken && getMsUntilTokenExpiry(cookieToken);
    if (!timeUntilExpiryCookie || timeUntilExpiryCookie < authSoftExpiryBufferMilliseconds) {
      const loginUrl = `/login?from=` + encodeURIComponent(window.location.pathname);
      navigate(loginUrl);
    } else {
      console.log(`User now has a valid token (assumed logged in via another tab). Reload entire app to ensure new user & token are used`)
      window.location.reload();
    }
    setIsModalOpen(false)
  };
  return (
    isModalOpen ?
      (
        <div>
          <AuthSoftExpiryModal open={isModalOpen} onClose={handleClose}/>
        </div>
      )
      : null
  )
};

function getMsUntilTokenExpiry(authHeaderOrToken) {
  const token = authHeaderOrToken?.replace("Bearer ", "");
  const decodedToken = jwtDecode(token);
  const expTime = decodedToken.exp; // Get the token's expiration time (assumed to be in seconds)
  const expirationDate = dayjs.unix(expTime); // Convert exp time to a Date object and subtract the current time
  const currentDate = dayjs();
  const timeUntilExpiry = expirationDate.diff(currentDate);
  return timeUntilExpiry;
}


export default AuthSoftExpiryCheck;