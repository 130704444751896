import React, { useCallback, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { renderCellComponent } from '../Datagrid/utils/utils';
import { useDateFormat } from '../../contexts/DateFormatContext';
import { useRecoilState } from 'recoil';
import { tileViewSettingsState } from '../../atoms/atoms';
import TileCard from './TileCard';

const TileView = ({
  items,
  groupBy,
  getMenuItems,
  getHighlightInfo = () => ({ isHighlighted: false }),
  onMenuItemClick,
}) => {
  const [tileViewSettings] = useRecoilState(tileViewSettingsState);
  const { dateFormat } = useDateFormat();

  const [menuState, setMenuState] = useState({
    anchorEl: null,
    selectedItem: null,
    menuItems: [],
  });

  const handleMenuClick = useCallback(
    (event, item) => {
      event.stopPropagation();
      // Pre-calculate menu items when opening the menu
      const items = getMenuItems(item);
      setMenuState({
        anchorEl: event.currentTarget,
        selectedItem: item,
        menuItems: items,
      });
    },
    [getMenuItems]
  );

  const handleMenuClose = useCallback(() => {
    setMenuState({
      anchorEl: null,
      selectedItem: null,
      menuItems: [],
    });
  }, []);

  const handleMenuItemClick = useCallback(
    (menuItem) => {
      onMenuItemClick(menuItem, menuState.selectedItem);
      handleMenuClose();
    },
    [menuState, onMenuItemClick, handleMenuClose]
  );

  // Memoize renderField function
  const renderField = useCallback(
    (item, field) => {
      if (field.dataType === 'image') return null;

      if (field.fieldName === 'inventoryItem' || field.fieldName === 'title') {
        return (
          <Typography variant="h6" component="div">
            {renderCellComponent(
              field,
              field.fieldName,
              { value: item[field.fieldName], row: item },
              () => {},
              dateFormat,
              false
            )}
          </Typography>
        );
      }

      return (
        <Box sx={{ mb: 1 }}>
          {tileViewSettings.showHeaders && (
            <Typography variant="body2" color="text.secondary">
              {field.displayName}:
            </Typography>
          )}
          <Box
            sx={{
              '& #card-text': {
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'normal',
                lineHeight: 1.4,
              },
            }}
          >
            {renderCellComponent(
              field,
              field.fieldName,
              { value: item[field.fieldName], row: item },
              () => {},
              dateFormat,
              false
            )}
          </Box>
        </Box>
      );
    },
    [dateFormat, tileViewSettings.showHeaders]
  );

  const imageField = useMemo(
    () =>
      tileViewSettings.columnsDisplayed?.find(
        (field) => field.dataType === 'image'
      ),
    [tileViewSettings.columnsDisplayed]
  );

  const groupedItems = useMemo(() => {
    if (!groupBy) return [{ items, groupName: 'All Items' }];

    const groups = items.reduce((acc, item) => {
      const groupValue = item[groupBy];
      if (!acc[groupValue]) {
        acc[groupValue] = {
          items: [],
          categoryIndex: item.categoryindex || 0,
        };
      }
      acc[groupValue].items.push(item);
      return acc;
    }, {});

    return Object.entries(groups)
      .map(([key, value]) => ({
        groupName: key,
        ...value,
      }))
      .sort((a, b) => a.categoryIndex - b.categoryIndex);
  }, [items, groupBy]);

  return (
    <Box gap={1}>
      {groupedItems?.length === 0 && (
        <Box
          p={2}
          width={100}
          m={'auto'}
          sx={{
            border: '1px solid',
            borderColor: 'divider',
            textAlign: 'center',
          }}
          mt={'20%'}
        >
          <Typography>No tiles</Typography>
        </Box>
      )}
      {groupedItems?.map((group, groupIndex) => (
        <Accordion key={groupIndex} defaultExpanded sx={{ mb: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${groupIndex}a-content`}
            id={`panel${groupIndex}a-header`}
          >
            <Typography variant="h6" fontWeight={'bold'}>
              {group.groupName} ({group.items.length} items)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {group.items.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                  <TileCard
                    item={item}
                    highlightInfo={getHighlightInfo(item)}
                    imageField={imageField}
                    tileViewSettings={tileViewSettings}
                    renderField={renderField}
                    onMenuClick={handleMenuClick}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}

      {menuState.anchorEl && (
        <Menu
          anchorEl={menuState.anchorEl}
          open={Boolean(menuState.anchorEl)}
          onClose={handleMenuClose}
        >
          {menuState.menuItems?.map((item, index) => (
            <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
};

export default TileView;
