import React, { useCallback, useEffect, useState } from "react";
import { Box, IconButton, Menu, MenuItem, useMediaQuery, useTheme, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchOrdersInput from "../../../components/SearchOrdersInput";
import FilterMenu from "../../../components/Filter";
import ViewToggleButtons from "../../CustomerOrders/components/ViewToggleButtons";
import TileView from "../../../components/TileView";
import Loading from "../../../components/Loading";
import ColumnSelector from "../../../components/TileView/ColumnSelector";
import { tileViewSettingsState } from "../../../atoms/atoms";
import { useRecoilState } from "recoil";
import { getSettings } from "../../../api/userSettings";

import HistoryModal from "../modals/historyModal";
import AllowOrdersWhenOutOfStock from "../modals/allowOrdersWhenOutOfStock";

const useSettings = (
  searchFieldOptions,
  searchFieldsLoading,
  setTileViewSettings,
  page,
  defaultSettings
) => {
  useEffect(() => {
    setTileViewSettings(null);
    if (searchFieldsLoading) return;

    getSettings(page, "tileViewSettings")
      .then((rsp) => {
        if (!rsp?.settings) {
          setTileViewSettings(defaultSettings);
        } else {
          const columnsDisplayed = rsp.settings.columnsDisplayed.map((column) => {
            const searchFieldOption = searchFieldOptions.find(
              (searchField) => searchField.fieldName === column.fieldName
            );
            return searchFieldOption || column;
          });
          setTileViewSettings({ ...rsp.settings, columnsDisplayed });
        }
      })
      .catch((err) => {
        console.log(err);
        setTileViewSettings(defaultSettings);
      });
  }, [page, searchFieldsLoading]);
};
export const InventoryTileView = ({
  page,
  sortedRows,
  searchFieldOptions,
  searchFieldsLoading,
  ordersLoading,
  refetch,
  view,
  toggleView,
  handleOrdersChange,
  bottomBarIsVisible,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tileViewSettings, setTileViewSettings] = useRecoilState(tileViewSettingsState);

  const [openAllowOrdersModal, setOpenAllowOrdersModal] = useState(false);
  const handleOpenHistoryModal = (item) => {
    setSelectedItem(item);
    setHistoryModalOpen(true);
  };

  const handleCloseHistoryModal = () => {
    setHistoryModalOpen(false);
    setSelectedItem(null);
  };

  const defaultSettings = {
    showHeaders: true,
    columnsDisplayed: searchFieldOptions.filter(
      (option) => !option.isHiddenForTile && option.fieldName !== "everywhere"
    ),
  };
  useSettings(searchFieldOptions, searchFieldsLoading, setTileViewSettings, page, defaultSettings);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewChange = () => {
    toggleView("table");
    handleMenuClose();
  };

  const getHighlightInfo = useCallback(
    (item) => {
      if (page === "stockPredictions") {
        if (item.criticallowstockalert) {
          return {
            isHighlighted: true,
            highlightText: "Critical Low Stock",
            highlightHeaderColour: "#ff3b30",
            highlightColor: "rgba(255,59,48,0.2)",
          };
        } else if (item.lowstockalert) {
          return {
            isHighlighted: true,
            highlightText: "Low Stock",
            highlightHeaderColour: "#ff3b30",
          };
        }
      } else if (page === "currentStock") {
        if (item.alloworderswhenoos) {
          return {
            isHighlighted: true,
            highlightText: "Allow Orders When Out of Stock",
            highlightHeaderColour: "primary.main",
            highlightColor: "#FFF3E0",
          };
        }
      }
      return { isHighlighted: false };
    },
    [page]
  );

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setOpenAllowOrdersModal(true);
  };
  const handleCloseModal = () => {
    setOpenAllowOrdersModal(false);
    setSelectedItem(null);
  };

  const handleMenuItemClick = async (menuItem, item) => {
    if (menuItem.action === "toggleAllowOrdersWhenOOS") {
      handleOpenModal(item);
    } else if (menuItem.action === "openHistoryModal") {
      handleOpenHistoryModal(item);
    }
  };

  const getMenuItems = useCallback(
    (item) => {
      const items = [
        {
          label: "Show History",
          action: "openHistoryModal",
        },
      ];
      if (page === "currentStock")
        items.push({
          label: "Hold / Out of Stock",
          action: "toggleAllowOrdersWhenOOS",
        });
      return items;
    },
    [page]
  );

  return (
    <>
      <AllowOrdersWhenOutOfStock
        selectedItem={selectedItem}
        handleOrdersChange={handleOrdersChange}
        open={openAllowOrdersModal}
        handleClose={handleCloseModal}
      />
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          background: theme.palette.background.paper,
          py: 0.5,
          px: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile && isSearchExpanded ? "column" : "row",
            alignItems: "center",
            width: "100%",
            gap: { xs: 0, sm: 0 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: isMobile && isSearchExpanded ? "100%" : "auto",
              flexGrow: 1,
            }}
          >
            {!isMobile && (
              <Box mr={1}>
                <ViewToggleButtons view={view} onToggleView={toggleView} viewType={"tile"} />
              </Box>
            )}
            <Box
              sx={{
                flexGrow: 1,
                width: isMobile && isSearchExpanded ? "100%" : "auto",
              }}
            >
              <SearchOrdersInput
                table={page}
                handleSearch={refetch}
                onSearchOpen={setIsSearchExpanded}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 1,
              width: "100%",
              mt: isMobile && isSearchExpanded ? 1 : 0,
            }}
          >
            <FilterMenu
              table={page}
              searchFieldOptions={searchFieldOptions}
              refetchOrders={refetch}
            />
            <ColumnSelector
              page={page}
              searchFields={searchFieldOptions}
              defaultSettings={defaultSettings}
            />
            {isMobile && (
              <Tooltip title="More options">
                <IconButton
                  size="small"
                  onClick={handleMenuClick}
                  sx={{
                    bgcolor: Boolean(anchorEl) ? "action.selected" : "transparent",
                    "&:hover": {
                      bgcolor: "action.hover",
                    },
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 3,
          sx: { minWidth: 200 },
        }}
      >
        <MenuItem onClick={handleViewChange}>Switch to Table View</MenuItem>
      </Menu>

      <Box
        sx={{
          height: {
            xs: `calc(100% - ${bottomBarIsVisible ? "228px" : "100px"})`,
            md: `calc(100% - ${bottomBarIsVisible ? "170px" : "100px"})`,
          },
          width: "100%",
          overflow: "auto",
        }}
      >
        {tileViewSettings?.columnsDisplayed && !ordersLoading ? (
          <Box p={1}>
            <TileView
              groupBy={"category"}
              items={sortedRows}
              getHighlightInfo={getHighlightInfo}
              getMenuItems={getMenuItems}
              onMenuItemClick={handleMenuItemClick}
            />
          </Box>
        ) : (
          <Box width={"40px"} m={"auto"} mt={4}>
            <Loading />
          </Box>
        )}
      </Box>
      {selectedItem && (
        <HistoryModal
          open={historyModalOpen}
          onClose={handleCloseHistoryModal}
          item={selectedItem}
          searchFields={searchFieldOptions}
        />
      )}
    </>
  );
};

export default InventoryTileView;
