import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LoadingButton from "@mui/lab/LoadingButton";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { login, wakeup } from "../../../api/Auth";

import { useCookies } from "react-cookie";
import { Paper } from "@mui/material";
import {loadLoginSuccessRedirect, loadUser, setLoginSuccessRedirect, setUser} from "../../../common";
import { authSoftExpiryBufferMilliseconds } from "../../../common";
import { useSetRecoilState } from "recoil";
import { cardViewSettingsState } from "../../../atoms/atoms";
import dayjs from "dayjs";
import {useRef} from "react";
import {jwtDecode} from "jwt-decode";

const theme = createTheme();

export default function SignIn() {
  const [cookies, setCookies, removeCookie] = useCookies(["token", "_auth"]);
  const setSettings = useSetRecoilState(cardViewSettingsState);
  const navigate = useNavigate();
  const loginDebounceTimer = useRef(null);
  const location = useLocation();
  const signIn = useSignIn();

  const [loading, setLoading] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [wasInteractiveSignIn, setWasInteractiveSignIn] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState("");

  React.useEffect(() => {
    // Only reload if the page hasn't been reloaded already
    const reloadTimestamp = +sessionStorage.getItem('LAST_LOGIN_RELOAD_TIMESTAMP');
    const hasReloadedRecently = reloadTimestamp > (Date.now() - 1000 * 60 * 5); // reloaded in last 5 mins
    if ((location.pathname === '/' || location.pathname === '/login') && !hasReloadedRecently) {
      // Do a full page reload to make sure we've got the most recent app
      console.log("No recent reload, performing full page reload.")
      sessionStorage.setItem('LAST_LOGIN_RELOAD_TIMESTAMP', "" + Date.now());
      window.location.reload();
    }

    // Do a sneaky wakeup if we've just reloaded. Not necessary, but may speed up login for the user
    const hasReloadedJustNow = reloadTimestamp > (Date.now() - 1000 * 10); // reloaded in last few seconds
    if (hasReloadedJustNow) {
      console.log(reloadTimestamp)
      wakeup().then(() => console.log("Wakeup sent, to help speed up endpoint :)"))
    }

    // Reset the flag when leaving the page to allow future reloads
    return () => {
      sessionStorage.removeItem('loginReloaded');
    };
  }, [location]);

  React.useEffect(() => {
    const handlerFunction = (e) => {
      if (e.code === "Enter") {
        e.preventDefault();
        handleSubmit(e);
      }
    };
    window.addEventListener("keydown", handlerFunction);
    // return cleanup function to remove the handler after use
    return () => {
      window.removeEventListener("keydown", handlerFunction);
    };
  }, [email, password]);

  // Navigate once logged in
  React.useEffect(() => {
    const user = JSON.parse(loadUser())?.id;
    const loginSuccessRedirect = loadLoginSuccessRedirect() || "/customerOrders"
    if (user && cookies._auth) {

      let tokenStartDate = getTokenIatDate(cookies._auth)
      let timeUntilExpiry = getMsUntilTokenExpiry(cookies._auth)
      let isValid = timeUntilExpiry > 0;
      let isTinyTestWindow = Date.now() - authSoftExpiryBufferMilliseconds - tokenStartDate.getTime() < 0;
      let isNearExpiry = isValid
        && timeUntilExpiry < authSoftExpiryBufferMilliseconds // we're close to the expiry period
        && (!isTinyTestWindow || timeUntilExpiry < 30_000) // check within 30sec of expiry if we're using a test debugging token with a short validity window

      if (isValid && !isNearExpiry) {
        // interactive logins redirect straight to main app
        // but non-interactive tabs need to be reloaded first after we have a valid cookie, to avoid wierd logout bug
        let reloadTimestamp = +sessionStorage.getItem('LAST_LOGIN_RELOAD_TIMESTAMP');
        let wasReloadedWithCookie = reloadTimestamp > tokenStartDate.getTime();

        if (wasInteractiveSignIn || wasReloadedWithCookie) {
          // make freshly-loaded non-interactive-login tabs wait a random few seconds, to avoid congestion
          let loginDelayTime = wasInteractiveSignIn ? 1 : Math.round(Math.random() * 3000 + 500)
          console.log(`Auth valid -> redirect in ${loginDelayTime}ms`)
          clearTimeout(loginDebounceTimer.current);
          loginDebounceTimer.current = setTimeout(() => navigate(loginSuccessRedirect), loginDelayTime); // allow more time for cookies to save etc
        } else {
          console.log("Auth valid, but this is a background tab, so reloading to avoid logout bug")
          sessionStorage.setItem('LAST_LOGIN_RELOAD_TIMESTAMP', "" + Date.now());
          window.location.reload();
        }
      }
      if (isValid && isNearExpiry) {
        console.log(`Auth valid but is about to expire, don't redirect.${isTinyTestWindow ? " TTW" : "" } Expires in secs: ` + Math.round(timeUntilExpiry / 100) / 10)
      }
      if (!isValid) {
        console.log("Auth present but expired/invalid")
      }
    }
    return () => clearTimeout(loginDebounceTimer.current);
  }, [cookies._auth, wasInteractiveSignIn, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const hasEmailError = !email;
    const hasPasswordError = !password;
    setErrorMessage("");
    setEmailError(hasEmailError);
    setPasswordError(hasPasswordError);

    if (hasEmailError || hasPasswordError) return;

    setLoading(true);
    login(email, password)
      .then((rsp) => {
        setLoading(false);
        setUser(JSON.stringify(rsp)); // saves to local storage
        if (rsp.loginSuccessRedirect) {
          setLoginSuccessRedirect(rsp.loginSuccessRedirect);
        }
        let authKitSignInOk = signIn({
          auth: {
            token: rsp.accessToken,
            type: "Bearer",
          },
          authState: {
            email: rsp.email,
            firstName: rsp.firstName,
            lastName: rsp.lastName,
          },
        });
        console.log("Auth-kit: signin ok? " + authKitSignInOk)
        setSettings({});
        setWasInteractiveSignIn(true)
        // no need to redirect here - signIn will have set cookies._auth, so redirect will happen on re-render
      })
      .catch((err) => {
        if (!err.response)
          setErrorMessage(
            "Error: We apologize, but something went wrong on our end."
          );
        else setErrorMessage(err.response.data);
        setLoading(false);
        setEmailError(false);
        setPasswordError(false);
        setPassword("");
        console.error(err);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        width={{ xs: "90%", sm: "500px" }}
        m={"auto"}
        sx={{ pt: 8, height: "100vh" }}
      >
        <Paper
          sx={{
            p: 4,
            pb: 5,
            borderRadius: "20px",
            textAlign: "center",
            background: "white",
          }}
        >
          <Avatar
            sx={{ m: 1, bgcolor: "secondary.main", margin: "auto", my: 1 }}
          >
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          {errorMessage && <Alert color="error"> {errorMessage} </Alert>}

          <Box noValidate sx={{ mt: 1 }}>
            <TextField
              autoFocus
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              error={emailError}
              helperText={emailError && "Email required."}
              InputLabelProps={{ shrink: true }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              error={passwordError}
              helperText={passwordError && "Password required."}
              InputLabelProps={{ shrink: true }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <LoadingButton
              type="submit"
              loading={loading}
              variant="outlined"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Sign In
            </LoadingButton>
            <Box sx={{ textAlign: "left" }}>
              <Link href="forgotpassword" variant="body2">
                Forgot password?
              </Link>
            </Box>
          </Box>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}

function getMsUntilTokenExpiry(authHeaderOrToken) {
  const token = authHeaderOrToken?.replace("Bearer ", "");
  const decodedToken = jwtDecode(token);
  const expTime = decodedToken.exp; // Get the token's expiration time (assumed to be in seconds)
  const expirationDate = dayjs.unix(expTime); // Convert exp time to a Date object and subtract the current time
  const currentDate = dayjs();
  const timeUntilExpiry = expirationDate.diff(currentDate);
  return timeUntilExpiry;
}

function getTokenIatDate(authHeaderOrToken) {
  const token = authHeaderOrToken?.replace("Bearer ", "");
  const decodedToken = jwtDecode(token);
  const iatDate = decodedToken.iat ? new Date(decodedToken.iat * 1000) : null; // Get the token's expiration time (assumed to be in seconds)
  return iatDate;
}
