import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { TableRowComponent } from "./TableRowComponent";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const NormalTable = ({
  columns,
  rows,
  handleInputChange,
  handleDelete,
  globalTimezone,
  maxHeight,
  allowBulkEdit,
  selectedRows,
  onRowSelect,
  onSelectAll,
  onBulkEdit,
  handleAdd,
  bulkEditHeaderText,
}) => {
  const allSelected = rows?.length > 0 && selectedRows?.length === rows?.length;

  const checkboxCellStyle = {
    minWidth: "48px",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    p: 2,
  };

  return (
    <TableContainer sx={{ maxHeight: maxHeight || "auto", overflow: "auto" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {allowBulkEdit && (
              <TableCell
                padding="checkbox"
                sx={{ ...checkboxCellStyle, textAlign: "center", ...(bulkEditHeaderText && { pt: 0.2, pb: 0 }) }}
              >
                {bulkEditHeaderText && (
                  bulkEditHeaderText
                )}
                <Checkbox
                  indeterminate={
                    selectedRows?.length > 0 &&
                    selectedRows?.length < rows?.length
                  }
                  checked={allSelected}
                  onChange={(e) => onSelectAll(e.target.checked)}
                />
              </TableCell>
            )}
            {columns.map(
              (column, index) =>
                (!column.hide ||
                  (typeof column.hide === "function" && !column.hide())) && (
                  <TableCell key={column.field} sx={checkboxCellStyle}>
                    {`${column.headerName} ${column.required ? "*" : ""} `}
                  </TableCell>
                )
            )}
            {handleDelete && <TableCell sx={{ width: 50 }} />}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRowComponent
              key={row.id}
              row={row}
              columns={columns}
              handleInputChange={handleInputChange}
              handleDelete={handleDelete}
              globalTimezone={globalTimezone}
              allowBulkEdit={allowBulkEdit}
              selected={selectedRows.includes(row.id)}
              onRowSelect={onRowSelect}
              onBulkEdit={onBulkEdit}
            />
          ))}
        </TableBody>
      </Table>
      {handleAdd && (
        <Box 
          display="flex" 
          alignItems="center" 
          sx={{ 
            p: 2, 
            borderTop: "1px solid rgba(224, 224, 224, 1)" 
          }}
        >
          <IconButton
            onClick={handleAdd}
            size="small"
            sx={{ mr: 1 }}
          >
            <AddCircleIcon fontSize="large" />
          </IconButton>
          Add {columns[0].headerName}
        </Box>
      )}
    </TableContainer>
  );
};

export default NormalTable;
