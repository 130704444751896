import React, { useEffect, useState } from "react";
import { getFieldValues } from "../../api/SearchFields";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { cardViewSettingsState, selectedCardsState } from "../../atoms/atoms";
import { shippingStatuses } from "../../common";
import { getSettings } from "../../api/cardSettings";
import Loading from "../Loading";
import Box from "@mui/material/Box";
import { DndContext, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { Typography, Checkbox, useTheme } from "@mui/material";
import { VirtualizedCardList } from './components/VirtualizedCardList';

const predefinedInventoryStatusOrder = [
  "ORDERED",
  "IN PRODUCTION",
  "ON SHIP",
  "IN CUSTOMS",
  "INTERNAL TRANSIT",
  "ARRIVED AT WAREHOUSE",
  "RECEIVED",
];

const predefinedStatusCNOrder = [
  "保留装运 (Hold Shipment)",
  "未发货 (Not Shipped)",
  "预定的 (Scheduled)",
  "已出货 (Shipped)",
  "运输问题 (Shipping Problem)",
  "已交付 (Delivered)",
  "取消 (Cancelled)",
];

const getOrderedColumns = (fieldName, fieldValues, data) => {
  let predefinedOrder = [];

  // Determine which predefined order to use based on fieldName
  if (fieldName === "status") {
    predefinedOrder = predefinedInventoryStatusOrder;
  } else if (fieldName === "shippingstatus") {
    predefinedOrder = shippingStatuses;
  } else if (fieldName === "suppliershippingstatus") {
    predefinedOrder = predefinedStatusCNOrder;
  }

  if (predefinedOrder.length > 0) {
    // Create columns for all predefined values, even if they have no cards
    return predefinedOrder
      .map((value) => ({
        id: value,
        title: value,
        cards: data?.filter((order) => order[fieldName] === value) || [],
      }))
      .filter((column) => {
        // Hide system columns that have 0 items
        let collapseIfEmptyColumnIds = [
          "SYS_ARRIVED_AT_CUSTOMS",
          "SYS_CUSTOMS_DELAY",
          "SYS_CUSTOMS_CLEARED",
          "SYS_TRANSIT_DELAY",
          "SYS_SHIPMENT_LOST",
          "ATTEMPTED_DELIVERY",
          "CANCELED",
        ];
        return !(
          column.cards?.length > 0 &&
          collapseIfEmptyColumnIds.includes(column.id)
        );
      });
  }

  // For non-predefined columns, use the existing logic
  return fieldValues.map((value) => ({
    id: value,
    title: value,
    cards: data?.filter((order) => order[fieldName] === value) || [],
  }));
};

const CardView = ({
  table,
  data,
  ordersLoading,
  searchFields,
  dataGridName,
  defaultColumnHeader,
}) => {
  const [boardData, setBoardData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const settings = useRecoilValue(cardViewSettingsState);
  const setSettings = useSetRecoilState(cardViewSettingsState);


  const sensors = useSensors(useSensor(PointerSensor));

  const [selectedCards, setSelectedCards] = useRecoilState(selectedCardsState);

  const theme = useTheme();

  useEffect(() => {
    setSelectedCards([]);
    setError(null);
    setLoading(true);
    setSettingsLoaded(false);
    const defaultSettings = {
      cardColumnHeaders: defaultColumnHeader,
      columnsDisplayed: searchFields.filter(
        (option) => !option.isHiddenForCard && option.fieldName !== "everywhere"
      ),
    };
    getSettings(dataGridName)
      .then((rsp) => {
        const userSettings = rsp.settings;

        const newSettings = {
          cardColumnHeaders:
            userSettings?.cardColumnHeaders ||
            defaultSettings.cardColumnHeaders,
          columnsDisplayed:
            userSettings?.columnsDisplayed || defaultSettings.columnsDisplayed,
          showColumnHeaders: userSettings?.showColumnHeaders ?? true,
          canMoveCards: userSettings?.canMoveCards ?? false,
        };

        setSettings(newSettings);
        setSettingsLoaded(true);
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage =
          "Error: failed to load settings, please try again later. ";
        setError(errorMessage);
        setBoardData([]);
        console.error("Error fetching settings:", err);
      });
  }, [table, dataGridName]);

  useEffect(() => {
    if (!settingsLoaded) return;

    const fieldName = settings?.cardColumnHeaders?.fieldName;
    if (!fieldName) {
      setLoading(false);
      return;
    }

    getFieldValues(table, fieldName)
      .then((rsp) => {
        if (rsp.results.length === 1) {
          setLoading(false);
          setBoardData([]);
          return setError(
            "Error getting orders, please try again with a different column header. "
          );
        }
        setError(null);
        const fieldValues = rsp.results;
        const dataType = searchFields.filter(
          (field) => field.fieldName === fieldName
        )[0]?.dataType;

        // Use the new getOrderedColumns function
        const columns = getOrderedColumns(fieldName, fieldValues, data).map(
          (col) => ({
            ...col,
            dataType: dataType,
          })
        );

        // Filter out any columns with null/undefined IDs
        setBoardData(columns.filter((column) => column.id));
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        console.log(err);
      });
  }, [settingsLoaded, settings, data, table, searchFields]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    if (active.id !== over.id) {
      setBoardData((columns) => {
        const oldColumnIndex = columns.findIndex(col => col.cards.some(card => card.id === active.id));
        const newColumnIndex = columns.findIndex(col => col.cards.some(card => card.id === over.id));
        const oldColumn = columns[oldColumnIndex];
        const newColumn = columns[newColumnIndex];

        const oldCardIndex = oldColumn.cards.findIndex(card => card.id === active.id);
        const newCardIndex = newColumn.cards.findIndex(card => card.id === over.id);

        const newColumns = [...columns];
        const [movedCard] = oldColumn.cards.splice(oldCardIndex, 1);
        newColumn.cards.splice(newCardIndex, 0, movedCard);

        return newColumns;
      });
    }
  };


  const hideCheckbox = (column) => {
    return column.cards.length === 0 ||
      (dataGridName === "customerOrders" && column.cards.some((card) => card.warehouse !== column.cards[0].warehouse)) || 
      (dataGridName === "stockOrders" && column.cards.some(card=> card.warehouseordernumber !== column.cards[0].warehouseordernumber));
    };

  const isColumnChecked = (column) => {
    return column.cards.every((item) =>
      selectedCards.map((item) => item.id).includes(item.id)
    );
  };

  const isColumnIndeterminate = (column) => {
    return column.cards.some((item) =>
      selectedCards.map((item) => item.id).includes(item.id)
    ) && !isColumnChecked(column);
  };

  const handleColumnSelect = (column) => {
    const checked = isColumnChecked(column);
    const indeterminate = isColumnIndeterminate(column);

    if (checked || indeterminate) {
      setSelectedCards((prev) =>
        prev.filter((card) => !column.cards.find((item) => item.id === card.id))
      );
    } else {
      setSelectedCards((prev) => [...prev, ...column.cards]);
    }
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {loading && (
        <Box width={"40px"} mx={"auto"} mt={4}>
          <Loading />
        </Box>
      )}
      {boardData && !loading && !ordersLoading && (
        <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
          <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                p: 1,
                overflowX: 'auto',
                height: '100%',
              }}
            >
              {boardData.map((column) => (
                <Box
                  key={column.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 320,
                    minWidth: 320,
                    bgcolor: theme.palette.background.default,
                    borderRadius: 2,
                    p: 1,
                    pr: 0,
                    pb: 1.5,
                    pt: 2,
                    boxShadow: 3,
                    position: 'relative',
                    "&:hover": {
                      boxShadow: 6,
                    },
                  }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    mb: 2,
                    position: 'relative'
                  }}>
                    {!hideCheckbox(column) && (
                      <Checkbox
                        checked={isColumnChecked(column)}
                        indeterminate={isColumnIndeterminate(column)}
                        onChange={() => handleColumnSelect(column)}
                        sx={{ position: 'absolute', right: 8 }}
                      />
                    )}
                    <Typography variant="h6" sx={{ textAlign: 'center', fontSize: 16 }}>
                      {column.title} ({column.cards.length})
                    </Typography>
                  </Box>
                  <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                    <VirtualizedCardList 
                      cards={column.cards}
                      table={table}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </DndContext>
        </Box>
      )}
    </Box>
  );
};

export default CardView;
