import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { getImage, renderCellComponent } from "../../../components/Datagrid/utils/utils";
import { useRecoilState } from "recoil";
import { tileViewSettingsState } from "../../../atoms/atoms";
import { useDateFormat } from "../../../contexts/DateFormatContext";

const ProductTile = ({ product, onQuantityChange, onLockToggle, handleDeleteOrder, isLocked }) => {
  const { dateFormat } = useDateFormat();

  const [anchorEl, setAnchorEl] = useState(null);
  const [tileViewSettings, setTileViewSettings] = useRecoilState(tileViewSettingsState);

  const handleLockToggle = () => {
    onLockToggle(product.pid, !isLocked);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    switch (option) {
      case "60days":
        onQuantityChange(product.id, product["stock60days"]);
        break;
      case "90days":
        onQuantityChange(product.id, product["stock90days"]);
        break;
      case "120days":
        onQuantityChange(product.id, product["stock120days"]);
        break;
      case "minShip":
        onQuantityChange(product.id, product["minimumshipqty"]);
        break;
      case "40ft":
        onQuantityChange(product.id, product["fortyftcontainernextorderqty"]);
        break;
      case "20ft":
        onQuantityChange(product.id, product["twentyftcontainernextorderqty"]);
        break;
      case "allNeeded":
        onQuantityChange(product.id, product["needtoorder"]);
        break;
    }

    handleMenuClose();
  };

  return (
    <Card sx={{ position: "relative", height: "100%" }}>
      <CardContent sx={{ p: "16px !important", textAlign: "center" }}>
        <IconButton
          onClick={handleLockToggle}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            borderColor: isLocked ? "primary.main" : "grey.400",
            borderWidth: 1,
            borderStyle: "solid",
            p: 1,
          }}
        >
          {isLocked ? <LockIcon color="primary" /> : <LockOpenIcon />}
        </IconButton>
        <IconButton
          onClick={() => handleDeleteOrder(product)}
          sx={{
            position: "absolute",
            top: 8,
            left: 8,
            p: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box height={{ xs: 180, sm: 200 }} width={{ xs: 200, sm: 250 }} mb={2} mx={"auto"}>
          <img
            style={{ width: "100%" }}
            alt={`${product.description}`}
            src={getImage(`${product.pid}.webp`)}
          />
        </Box>
        <Box mb={2}>
          {tileViewSettings?.columnsDisplayed.map((column) => (
            <Box textAlign={"left"} key={column?.fieldName}>
              {tileViewSettings.showHeaders && (
                <Typography variant="body2" color="text.secondary">
                  {column.displayName}:
                </Typography>
              )}
              {renderCellComponent(
                column,
                column.fieldName,
                { value: product[column.fieldName], row: product },
                () => {},
                dateFormat,
                false
              )}
            </Box>
          ))}
        </Box>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={() => handleMenuItemClick("60days")}>60 days of stock</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("90days")}>90 days of stock</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("120days")}>120 days of stock</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("minShip")}>Minimum ship Qty</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("40ft")}>
            40ft Container Recommendation
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("20ft")}>
            20ft Container Recommendation
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("allNeeded")}>All Needed</MenuItem>
        </Menu>
        <Box display={"flex"} gap={2} alignItems={"center"} width={250} mx={"auto"}>
          <Typography variant={"h6"}>Quantity: </Typography>
          <TextField
            type={"number"}
            sx={{ width: 100 }}
            value={product.qty}
            onChange={(e) => onQuantityChange(product.id, e.target.value)}
            InputProps={{
              inputProps: {
                type: "number",
                min: 0,
              },
            }}
          />{" "}
          <IconButton onClick={handleMenuOpen}>
            <MoreVertIcon />
          </IconButton>
        </Box>{" "}
      </CardContent>
    </Card>
  );
};

export default ProductTile;
