import { useRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import { getImage } from "../../../components/Datagrid/utils";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import Table from "../../../components/Table";
import { getProductsByCountryAndWarehouse } from "../../../api/Products";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Loading from "../../../components/Loading";
import DatePicker from "../../../components/DatePicker";
import dayjs from "dayjs";
import Error from "../../../components/Error";

const EditAuditCorrection = ({ data }) => {
  const [state, setState] = useRecoilState(modalState);

  const [products, setProducts] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    setState({
      dateOrdered: new dayjs(data?.orders[0]?.dateOrdered),

      rows: data.orders.map((order) => ({
        ...order,
        productimage: `${order.pid}.webp`,
      })),
    });
  }, [data]);

  useEffect(() => {
    const warehouse = data.orders[0].warehouse;
    getProductsByCountryAndWarehouse(warehouse.slice(0, 2), warehouse)
      .then((rsp) => {
        setProducts(rsp.countryProducts);
      })
      .catch((err) => {
        setError(
          "An error occurred while loading the product list. Please try again later."
        );
      });
  }, []);

  const handleTableChange = (value, field, id) => {
    if (field === "pid") {
      setState((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((order) => {
          if (order.id === id) {
            return {
              ...order,
              pid: value?.pid,
              inventoryitem: value?.description,
              productimage: `${value.pid}.webp`,
            };
          } else {
            return order;
          }
        }),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,

        rows: prevState.rows.map((order) => {
          if (order.id === id) {
            return { ...order, [field]: value };
          } else {
            return order;
          }
        }),
      }));
    }
  };

  const columns = [
    { field: "rownumber", headerName: "Row Number", width: 100 },
    {
      field: "pid",
      headerName: "Product",
      width: 600,
      editable: true,
      renderEditCell: ({ row, onValueChange }) => (
        <Autocomplete
          // Find the matching product object from products array
          value={products.find((product) => product.pid === row.pid) || null}
          options={products}
          getOptionLabel={(option) =>
            option ? `${option.pid} (${option.description})` : ""
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search products"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    {params.inputProps.value && (
                      <img
                        src={getImage(`${row.pid}.webp`)}
                        alt={row.pid}
                        style={{
                          width: 30,
                          height: 30,
                          objectFit: "contain",
                          marginRight: 8,
                          marginLeft: 4,
                        }}
                      />
                    )}
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(event, newValue) =>
            onValueChange(newValue, "supplier", row.id)
          }
          filterOptions={(options, { inputValue }) => {
            const searchTerm = inputValue.toLowerCase();
            return options.filter(
              (option) =>
                option.description.toLowerCase().includes(searchTerm) ||
                option.pid.toLowerCase().includes(searchTerm)
            );
          }}
          isOptionEqualToValue={(option, value) => {
            // Handle both object and string comparison
            if (!option || !value) return false;
            return option.pid === value.pid;
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <Box display="flex" alignItems="center" gap={2}>
                <img
                  src={getImage(`${option.pid}.webp`)}
                  alt={option.description}
                  style={{ width: 50, height: 50, objectFit: "contain" }}
                />
                <Box>
                  <strong>{option.pid}</strong>
                  <Typography variant="body2" color="text.secondary">
                    {option.description}
                  </Typography>
                </Box>
              </Box>
            </li>
          )}
        />
      ),
    },
    {
      field: "quantityordered",
      headerName: "Quantity",
      type: "number",
      width: 50,
      editable: true,
    },

    {
      field: "tradefinanceorder",
      headerName: "Trade Finance",
      type: "boolean",
      editable: true,
      width: 75,
    },
    {
      field: "notes",
      headerName: "Notes",
      editable: true,
      renderEditCell: ({ row, onValueChange }) => (
        <TextField
          multiline
          rows={3}
          value={row.notes || ""}
          onChange={(e) => onValueChange(e.target.value, "notes", row.id)}
          size="small"
          fullWidth
        />
      ),
    },
  ];

  return (
    <Box>
      {error && <Error errorMessage={error} />}
      {!products && (
        <Box width={40} sx={{ m: "auto" }}>
          <Loading />
        </Box>
      )}
      {products && (
        <Box>
          <Box mb={2}>
            <DatePicker
              label={"Date Ordered"}
              value={state?.dateOrdered}
              onChange={(newValue) =>
                setState({ ...state, dateOrdered: dayjs(newValue) })
              }
              startingTimezone={data.orders[0].warehouse.slice(0, 2)}
              required
            />
          </Box>
          <Table
            columns={columns}
            rows={state.rows}
            onCellChange={handleTableChange}
            allowBulkEdit
          />
        </Box>
      )}
    </Box>
  );
};
export default EditAuditCorrection;
