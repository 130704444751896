import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import DialogContentText from "@mui/material/DialogContentText";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import dayjs from "dayjs";
import Table from "../../../components/Table";
import DatePicker from "../../../components/DatePicker";
import FormControlLabel from "@mui/material/FormControlLabel";

const CustomCheckbox = React.memo(({ checked, onChange, disabled }) => {
  return <Checkbox checked={checked} onChange={onChange} disabled={disabled} />;
});

const MarkAsDeliveredContent = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);
  const [selectedTrackingNumber, setSelectedTrackingNumber] = useState(null);
  const [localSelection, setLocalSelection] = useState([]);
  const [allowSelectAll, setAllowSelectAll] = useState(false);

  useEffect(() => {
    setState({
      date: dayjs(),
      selectionModel: data.selectedItem ? [data.selectedItem.id] : [],
    });
    setSelectedTrackingNumber(data.selectedItem?.trackingnumber);
    setLocalSelection(data.selectedItem ? [data.selectedItem.id] : []);
  }, [data, setState]);

  useEffect(() => {
    setLocalSelection(state.selectionModel || []);
  }, [state.selectionModel]);

  const handleRowSelection = useCallback(
    (value, field, id) => {
      if (field !== "selection") return;

      const selectedRow = data.items.find((item) => item.id === id);
      if (!selectedRow) return;

      setState((prevState) => {
        let newSelectionModel;

        if (value) {
          if (
            allowSelectAll ||
            !selectedTrackingNumber ||
            selectedTrackingNumber === selectedRow.trackingNumber
          ) {
            if (!allowSelectAll) {
              setSelectedTrackingNumber(selectedRow.trackingNumber);
            }
            newSelectionModel = [...prevState.selectionModel, id];
          } else {
            // Can't select items with different tracking numbers unless allowSelectAll is true
            return prevState;
          }
        } else {
          newSelectionModel = prevState.selectionModel.filter(
            (itemId) => itemId !== id
          );
          if (newSelectionModel.length === 0) {
            setSelectedTrackingNumber(null);
          }
        }
        setLocalSelection(newSelectionModel);
        return { ...prevState, selectionModel: newSelectionModel };
      });
    },
    [data.items, selectedTrackingNumber, setState, allowSelectAll]
  );

  const handleAllowSelectAllChange = (event) => {
    setAllowSelectAll(event.target.checked);
    if (!event.target.checked) {
      // When disabling "allow select all", clear selection if multiple tracking numbers
      const selectedItems = data.items.filter((item) =>
        localSelection.includes(item.id)
      );
      const trackingNumbers = new Set(
        selectedItems.map((item) => item.trackingNumber)
      );

      if (trackingNumbers.size > 1) {
        setLocalSelection([]);
        setState((prev) => ({ ...prev, selectionModel: [] }));
        setSelectedTrackingNumber(null);
      }
    }
  };

  const scheduleColumns = [
    {
      field: "selection",
      headerName: "",
      width: 50,
      editable: true,
      renderEditCell: (params) => {
        const isSelectable =
          allowSelectAll ||
          !selectedTrackingNumber ||
          params.row.trackingNumber === selectedTrackingNumber;

        const isChecked = localSelection.includes(params.row.id);

        return (
          <CustomCheckbox
            checked={isChecked}
            onChange={(event) =>
              handleRowSelection(
                event.target.checked,
                "selection",
                params.row.id
              )
            }
            disabled={!isSelectable}
          />
        );
      },
    },
    { field: "warehouse", headerName: "Warehouse", flex: 1 },
    { field: "id", headerName: "UID", flex: 3 },
    { field: "description", headerName: "Description", flex: 3 },
    { field: "qty", headerName: "Qty", type: "number", flex: 1 },
    { field: "shippingCompany", headerName: "Shipping Company", flex: 3 },
    { field: "consignmentNumber", headerName: "Consignment Number", flex: 3 },
    { field: "trackingNumber", headerName: "Tracking Number", flex: 3 },
    { field: "shippingStatus", headerName: "Status", flex: 2 },
  ];

  return (
    <Box>
      <DialogContentText sx={{ mb: !data.product ? 0 : 3 }}>
        Manually mark these items as delivered.
      </DialogContentText>

      <Box mt={2} mb={1}>
        <Table
          key={`${localSelection.join(",")}-${allowSelectAll}`}
          columns={scheduleColumns}
          rows={data.items || []}
          onCellChange={handleRowSelection}
        />
      </Box>
      <Box mt={1} mb={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={allowSelectAll}
              onChange={handleAllowSelectAllChange}
            />
          }
          label="Allow select all"
        />
      </Box>
      <Box width={{ xs: "100%", sm: 350 }}>
        <DatePicker
          label={"Date Delivered"}
          value={state.date}
          onChange={(newValue) => setState({ ...state, date: dayjs(newValue) })}
          required
          startingTimezone={data?.ordercountrycode}
        />
      </Box>
    </Box>
  );
};

export default MarkAsDeliveredContent;
