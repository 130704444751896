import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import MainContainer from "../../../../layouts/main";
import Box from "@mui/material/Box";
import { CircularProgress, Paper, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const Dashboards = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getDashboardUrl = () => {
    if (location.pathname.includes("/machine")) {
      return "https://app.smartsheet.com/b/publish?EQBCT=d833587b4ee64998bf796b00a9fa752c";
    }
    if (location.pathname.includes("/accessories")) {
      return "https://app.smartsheet.com/b/publish?EQBCT=a0f6c69585ef4118b934654177c85049";
    }
    return "";
  };

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const handleIframeError = () => {
    setLoading(false);
    setError(true);
  };

  let title = location.pathname.split("/").pop();
  title = title.charAt(0).toUpperCase() + title.slice(1);

  return (
    <MainContainer title={`Dashboards: ${title}`}>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 50px)",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            height: "100%",
            position: "relative",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <CircularProgress />
              <Typography>Loading dashboard...</Typography>
            </Box>
          )}

          {error && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                p: 3,
                textAlign: "center",
              }}
            >
              <ErrorOutlineIcon color="error" sx={{ fontSize: 48 }} />
              <Typography variant="h6" color="error">
                Unable to load Smartsheet dashboard
              </Typography>
              <Typography color="text.secondary">
                Please ensure you are logged into Smartsheet and have the necessary permissions. You
                may need to open the dashboard in a new tab first.
              </Typography>
              <Box sx={{ mt: 2 }}>
                <a
                  href={getDashboardUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: (theme) => theme.palette.primary.main,
                    textDecoration: "none",
                  }}
                >
                  Open dashboard in new tab
                </a>
              </Box>
            </Box>
          )}

          <iframe
            title="Smartsheet Dashboard"
            src={getDashboardUrl()}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              display: error ? "none" : "block",
            }}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
            allow="fullscreen"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals"
          />
        </Paper>
      </Box>
    </MainContainer>
  );
};

export default Dashboards;
