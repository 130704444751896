import React, { createContext, useContext, useState } from "react";
import ErrorSnackbar from "../components/ErrorSnackbar/ErrorSnackbar";

const ErrorContext = createContext(null);

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useError must be used within an ErrorProvider");
  }
  return context;
};

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const showError = (message) => {
    setError(message);
  };

  const clearError = () => {
    setError(null);
  };

  return (
    <ErrorContext.Provider value={{ showError, clearError }}>
      {children}
      <ErrorSnackbar
        open={Boolean(error)}
        message={error}
        onClose={clearError}
      />
    </ErrorContext.Provider>
  );
};
