import axios from "./axios";
import { API_ORDER_LINK_URL } from "../consts";

function getProductsByCountry(countryCode) {
  return axios
    .get(
      `${API_ORDER_LINK_URL}/getProductsByCountry?countryCode=${countryCode}`
    )
    .then((response) => response.data);
}

function getProductFields(countryCode) {
  return axios
    .get(
      `${API_ORDER_LINK_URL}/getSummarizedLineItemFields?countryCode=${
        countryCode || ""
      }`
    )
    .then((response) => response.data);
}

function getProductsByCountryAndWarehouse(countryCode, warehouse) {
  return axios
    .get(
      `${API_ORDER_LINK_URL}/getProductsByCountryAndWarehouse?countryCode=${countryCode}&warehouse=${warehouse}`
    )
    .then((response) => response.data);
}

function getProducts(countryCode, warehouse) {
  return axios
    .get(
      `${API_ORDER_LINK_URL}/products?countryCode=${countryCode}&warehouse=${warehouse}
`
    )
    .then((response) => response.data);
}

export {
  getProductFields,
  getProductsByCountry,
  getProductsByCountryAndWarehouse,
  getProducts,
};
