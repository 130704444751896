import React, { useRef } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Card } from './Card';
import { Box } from '@mui/material';

export const VirtualizedCardList = ({ cards, table }) => {
  const parentRef = useRef(null);
  const estimateSize = useRef(150);

  const rowVirtualizer = useVirtualizer({
    count: cards.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => estimateSize.current,
    overscan: 3,
    paddingStart: 4,
    paddingEnd: 4,
    scrollingDelay: 50,
    measureElement: (element) => {
      const height = element.getBoundingClientRect().height;
      if (Math.abs(height - estimateSize.current) > 20) {
        estimateSize.current = height;
      }
      return height;
    },
  });

  return (
    <Box 
      ref={parentRef}
      sx={{ 
        height: '100%',
        overflow: 'auto',
        position: 'relative',
        willChange: 'transform',
        WebkitOverflowScrolling: 'touch',
      }}
    >
      <SortableContext 
        items={cards.map(card => card.id)}
        strategy={verticalListSortingStrategy}
      >
        <Box
          sx={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => (
            <Box
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={rowVirtualizer.measureElement}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`,
                px: 1,
                mb: 2,
                willChange: 'transform',
              }}
            >
              <Card
                table={table}
                item={cards[virtualRow.index]}
              />
            </Box>
          ))}
        </Box>
      </SortableContext>
    </Box>
  );
}; 