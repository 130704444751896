import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { formatDate } from "../../utils/dateFormatter";
import { currencyFormatter } from "../../helpers/money/CurrencyFormater";

const flagsSmall = require.context("../../images/flags-small", true, /\.webp$/);
const flagsLarge = require.context("../../images/flags-large", true, /\.webp$/);
const products = require.context("../../images/products", true, /\.webp$/);
export const getRowClassName = (params) => {
  const isGroupRow = Object.getOwnPropertySymbols(params.row).some(
    (sym) => sym.toString() === "Symbol(mui.id_autogenerated)"
  );
  if (isGroupRow) return "grid-row";
  const row = params["row"];
  if (row["canceled"]) return "cancelled-theme";
  if (row["orderpaymentstatus"] === "NOT_PAID") return "unpaid-theme";
  if (row["inProgressMarkAsPaid"]) return "inProgressMarkAsPaid-theme";
  if (row["itemPID"] !== undefined && !row["itemPID"]) return "missingItemPID-theme";
};

export const onColumnVisibilityModelChange = (newModel, storage, setColumnVisibilityModel) => {
  localStorage.setItem(storage.visibilityModel, JSON.stringify(newModel));
  setColumnVisibilityModel(newModel);
};

export const onColumnOrderChange = (
  columnOrder,
  handleRowSelection,
  dataGridName,
  gridColumns,
  storage
) => {
  let { oldIndex, targetIndex } = columnOrder;
  // remove selection column
  if (handleRowSelection || dataGridName === "offlineOrders") {
    oldIndex -= 1;
    targetIndex -= 1;
  }

  const newColumns = [...gridColumns.current];
  const [reorderedColumn] = newColumns.splice(oldIndex, 1);
  newColumns.splice(targetIndex, 0, reorderedColumn);

  gridColumns.current = newColumns;

  // Save the new column order
  const order = newColumns.map((col) => col.field);
  localStorage.setItem(`${dataGridName}_columnOrder`, JSON.stringify(order));

  // Save column properties (including order)
  const columnProperties = newColumns.map((col, index) => ({
    field: col.field,
    width: col.width,
    isPinned: col.isPinned,
    isHidden: col.isHidden,
    order: index,
  }));

  localStorage.setItem(`${dataGridName}_columnProperties`, JSON.stringify(columnProperties));
};

export const onColumnWidthChange = (e, gridColumns, dataGridName) => {
  const isGroupColumn = e.colDef.field === "__row_group_by_columns_group__";
  let index;
  isGroupColumn
    ? (index = gridColumns.current.findIndex((col) => col.displayName === e.colDef.headerName))
    : (index = gridColumns.current.findIndex((col) => col.field === e.colDef.field));

  if (index !== -1) {
    gridColumns.current[index].width = e.colDef.width;

    // Save the updated column properties
    const columnProperties = gridColumns.current.map((col) => ({
      field: col.field,
      width: col.width,
      isPinned: col.isPinned,
      isHidden: col.isHidden,
    }));

    localStorage.setItem(`${dataGridName}_columnProperties`, JSON.stringify(columnProperties));
  }
};

export const getImage = (imageName) => {
  const contexts = [flagsSmall, flagsLarge, products];
  for (const context of contexts) {
    try {
      if (imageName.includes(":")) imageName = imageName.replace(/:/g, "_");
      if (imageName.includes("|")) imageName = imageName.replace(/\|/g, "_");
      return context(`./${imageName}`);
    } catch (e) {
      // Image not found in this context, continue to the next one
    }
  }
  // console.warn(`Image not found: ${imageName}`);
  return null; // Or return a default image URL
};

export const renderCellComponent = (
  searchField,
  fieldName,
  params,
  onRowClick,
  dateFormat,
  isInTable
) => {
  const cellStyle = {
    margin: isInTable ? "auto" : "0",
    whiteSpace: "normal",
    wordBreak: "break-word",
    overflowWrap: "break-word",
  };

  if (searchField?.hyperlink) {
    const link = params.row[searchField?.hyperlink.toLowerCase()];
    return (
      <a
        style={{
          ...cellStyle,
          display: "block",
          textDecoration: link ? "underline" : "inherit",
          color: link ? "blue" : "inherit",
        }}
        target="_blank"
        rel="noopener noreferrer"
        href={link}
      >
        <Typography component="span">{params.value}</Typography>
      </a>
    );
  } else if (searchField.dataType === "boolean") {
    return (
      <Box id={"card-text"} className={"card-text"} sx={{ width: isInTable ? "100%" : "auto" }}>
        <Checkbox disabled={!searchField?.isEditable} checked={params["row"][fieldName]} />
      </Box>
    );
  } else if (searchField.dataType === "image") {
    if (!params.value) return;
    const imageUrl = getImage(params.value) || params.value;
    return onRowClick ? (
      <a
        id={"card-text"}
        className="hover-pointer card-text"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          width: "50px",
        }}
        onClick={(e) => onRowClick(params, e)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          id={"card-text"}
          className={"card-text image"}
          style={{ width: "50px" }}
          height="auto"
          src={imageUrl}
          alt={params.row.flag}
          loading="lazy"
        />
      </a>
    ) : (
      <img
        id={"card-text"}
        className={"card-text"}
        height="auto"
        style={{ margin: "auto", width: "50px" }}
        src={imageUrl}
        alt={params.row.flag}
        loading="lazy"
      />
    );
  } else if (searchField.dataType === "date") {
    const date = params.row[fieldName] ? formatDate(params.row[fieldName], dateFormat) : "";
    return (
      <Typography
        id={"card-text"}
        className={"card-text"}
        sx={
          isInTable
            ? { display: "block !important", textAlign: "center" }
            : { "&:hover": { cursor: "default" } }
        }
      >
        {date}
      </Typography>
    );
  } else if (searchField.dataType === "number") {
    let displayValue = params.value;

    if (searchField.isPercent) {
      displayValue = `${params.value ? (params.value * 100).toFixed(2) : 0}%`;
    } else if (searchField.isCurrency) {
      displayValue = currencyFormatter(params.row?.ordercountrycode).format(params.value);
    }
    return (
      <Typography
        id={"card-text"}
        className={"card-text"}
        sx={
          isInTable
            ? { display: "block !important", textAlign: "center" }
            : { "&:hover": { cursor: "default" } }
        }
      >
        {displayValue}
      </Typography>
    );
  } else {
    return (
      <Typography
        id={"card-text"}
        className={"card-text"}
        sx={
          isInTable
            ? { display: "block !important", textAlign: "center" }
            : { "&:hover": { cursor: "default" } }
        }
      >
        {params.value}
      </Typography>
    );
  }
};
