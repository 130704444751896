import React, { useEffect, useRef } from "react";
import { Box, Button, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { orderSettingsState, permissionsState } from "../../../../atoms/atoms";
import { useDateFormat } from "../../../../contexts/DateFormatContext";
import { renderCellComponent } from "../../../../components/Datagrid/utils/utils";
import ItemActionsMenu from "../../components/ItemActionsMenu";
import NewOrRefurbDialog from "./NewOrRefurb";
import ProductImage from "./ProductImage";
import Loading from "../../../../components/Loading";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation } from "react-router-dom";

const images = require.context("../../../../images/flags-large", true, /\.webp$/);

const Details = ({ product, longestLabelLength }) => {
  const { dateFormat } = useDateFormat();
  const orderSettings = useRecoilValue(orderSettingsState);

  return (
    <Grid container spacing={0.2}>
      {!orderSettings?.columnsDisplayed && <Loading />}
      {orderSettings?.columnsDisplayed?.map((field) => {
        if (field.level === "item") {
          return (
            <Grid
              container
              item
              xs={12}
              key={field.fieldName}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              <Box
                sx={
                  field.dataType === "boolean"
                    ? { minWidth: "max-content", alignContent: "center" }
                    : {}
                }
              >
                <Typography
                  fontWeight="bold"
                  sx={{
                    mr: { xs: 0.3, sm: 1 },
                    minWidth: { xs: "auto", sm: `${longestLabelLength}ch` },
                    wordBreak: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                  }}
                >
                  {field.label}:
                </Typography>
              </Box>
              <Box
                sx={{
                  minWidth: "50%",
                  wordBreak: "break-word",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                }}
              >
                {renderCellComponent(
                  field,
                  field.fieldName,
                  { value: product[field.fieldName], row: product },
                  null,
                  dateFormat
                )}
              </Box>
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );
};

const FlagImage = ({ flag, isMobile }) => {
  const getImage = (imageName) => {
    try {
      return images(`./${imageName}`);
    } catch (e) {
      console.warn(`Could not find image: ${imageName}`);
      return null;
    }
  };

  const renderImage = (src) => (
    <Box display="flex" justifyContent="center" alignItems="center" height={"100%"} width="100%">
      <img
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        }}
        src={src}
        alt={flag.slice(0, -4)}
        loading="lazy"
      />
    </Box>
  );

  return renderImage(getImage(flag));
};
const ProductDetailsCard = ({
  product,
  handleActionSelect,
  longestLabelLength,
  requiresBarcode,
  scannedSerialNumber,
  allProducts,
  isScanView,
  onOpenScanModal,
}) => {
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const permissions = useRecoilValue(permissionsState);

  const buttonRef = useRef();

  const shouldDisplayProductActionsMenu =
    product.pid.split("-")[0] !== "XTRA" &&
    !product["canceled"] &&
    (product["ordersource"] !== "wf-offline" ||
      (product["ordersource"] === "wf-offline" && product["orderpaymentstatus"] === "PAID"));

  useEffect(() => {
    // focus the scan button for the first product
    if (isScanView && product === allProducts[0] && buttonRef.current) {
      buttonRef.current.focus();
      console.log("Focus scan button for " + product.uid);
    }
  }, []);

  useEffect(() => {
    const scannableOrderUnits =
      location?.state?.orderUnits.filter(
        (orderUnit) =>
          (orderUnit.pid.startsWith("CMDH") || orderUnit.pid.startsWith("DMDH")) &&
          !orderUnit.serialnumber
      ) || [];
    if (scannableOrderUnits.length === 1) {
      onOpenScanModal(scannableOrderUnits[0].uid);
    }
  }, []);

  const renderScanButton = () => (
    <Button
      variant="contained"
      color={scannedSerialNumber || product.serialnumber ? "success" : "primary"}
      startIcon={scannedSerialNumber ? <CheckCircleIcon /> : <QrCodeScannerIcon />}
      onClick={() => onOpenScanModal(product.uid)}
      sx={{ lineHeight: 1, py: 1 }}
      ref={buttonRef}
    >
      <Typography fontSize={{ xs: 12, md: 12, lg: 14 }}>
        {scannedSerialNumber ? `Scanned (${scannedSerialNumber})` : "Scan Serial Number"}
      </Typography>
    </Button>
  );

  return (
    <Paper sx={{ p: 2, mb: 1 }}>
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems="stretch"
      >
        {requiresBarcode && isMobile && isScanView && <Box mb={2.5}>{renderScanButton()}</Box>}
        {!isMobile && (
          <Box width={160} mr={2} display="flex" alignItems="center" justifyContent="center">
            <ProductImage product={product} />
          </Box>
        )}
        <Box flex={1} display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Box>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                {product.descriptionshort}
              </Typography>
              {product.uid.split("-").includes("CMDH") && (
                <NewOrRefurbDialog product={product} permissions={permissions} />
              )}
            </Box>
          </Box>
          <Box mt={2} flex={1}>
            <Details longestLabelLength={longestLabelLength} product={product} />
          </Box>
        </Box>
        {!isMobile && (
          <Box display="flex" flexDirection="column" ml={2}>
            {requiresBarcode && isScanView
              ? renderScanButton()
              : shouldDisplayProductActionsMenu &&
                !isScanView && (
                  <ItemActionsMenu onActionSelect={handleActionSelect} product={product} />
                )}
            <Box
              sx={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                width: 150,
              }}
            >
              <FlagImage flag={product.flag} isMobile={isMobile} />
            </Box>
          </Box>
        )}
      </Box>
      {isMobile && (
        <Box display="flex" mt={2} justifyContent="space-between" flexDirection="column" gap={2}>
          <Box width="40%" display="flex" justifyContent="center">
            <ProductImage product={product} />
          </Box>
          <Box width="50%" display="flex" justifyContent="center" alignItems="center">
            <FlagImage flag={product.flag} isMobile={isMobile} />
          </Box>
          {shouldDisplayProductActionsMenu && !isScanView && (
            <Box display="flex" justifyContent="flex-start">
              <ItemActionsMenu onActionSelect={handleActionSelect} product={product} />
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
};

export default ProductDetailsCard;
